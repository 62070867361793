import { combineReducers, createStore } from "redux";
import AuthSlice from "../slice/AuthSlice";
import DqeEditorSlice from "../slice/DqeEditorSlice";
import AdminSlice from "../slice/AdminSlice";

const allReducer = combineReducers({
  auth: AuthSlice,
  dqeEditor: DqeEditorSlice,
  admin: AdminSlice,
});

const store = createStore(
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
