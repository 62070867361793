import React, { useEffect, useState } from "react";
import { Input, InputGroup, Stack } from "@chakra-ui/react";
import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";

import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export const SearchAutoComplete = (props) => {
  const { allItems } = props;

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (allItems.length > 0) {
      setItems(allItems);
    }
  }, [allItems]);

  useEffect(() => {
    props.onItemSelect(selectedItems.map((item) => item.Name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const removeSelectedItem = (index) => {
    const item = selectedItems[index];
    let tempData = [];
    Object.assign(tempData, selectedItems);
    tempData.splice(index, 1);
    setSelectedItems(tempData);

    let tempData2 = [];

    Object.assign(tempData2, items);
    tempData2.push(item);
    tempData2.sort((a, b) => a.Name.localeCompare(b.Name));
    setItems(tempData2);
  };
  return (
    <Stack>
      <Stack spacing={2} isInline flexWrap="wrap">
        {selectedItems.map((item, index) => (
          <Tag mb={1} key={index}>
            <Tooltip label={item.Description}>
              <TagLabel>{item.Name}</TagLabel>
            </Tooltip>
            <TagCloseButton
              onClick={(e) => {
                e.stopPropagation();
                removeSelectedItem(index);
              }}
            />
          </Tag>
        ))}
      </Stack>
      <Stack spacing={0} zIndex="1000">
        <Menu m={3} p={3}>
          {({ isOpen }) => (
            <>
              <MenuButton isActive={isOpen}>
                <InputGroup size="sm">
                  <Input placeholder="Select Annotation Type" />
                </InputGroup>
              </MenuButton>
              <MenuList>
                {items.map((item, index) => (
                  <MenuItem
                    key={`search-menu-${index}`}
                    title={item.Description}
                    onClick={() => {
                      const data = [];
                      Object.assign(data, selectedItems);
                      data.push(items[index]);
                      // props.onAnnotationSelect(data);
                      setSelectedItems(data);

                      const tempData = [];
                      Object.assign(tempData, items);
                      tempData.splice(index, 1);
                      setItems(tempData);
                    }}
                  >
                    {item.Name}
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Stack>
    </Stack>
  );
};
