import React from "react";
import { Box, Flex, HStack, Stack, Text, useColorMode } from "@chakra-ui/react";

import { textColor, bgColorItem } from "../../../../../config/Theme";
import { getAnnotationIcon } from "../../../../../assets/annotationIcons";

const ProposedRelationItem = ({
  id,
  name,
  annotationType,
  modifiedBy,
  status,
  finalVersion,
  parentPath,
  changedOn,
  isParent,
  actions,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderRadius="sm"
      border="1px"
      borderColor={
        finalVersion === 0
          ? status === "Create"
            ? "green.300"
            : "red.300"
          : "gray.300"
      }
      w={350}
      color={textColor[colorMode]}
      bg={bgColorItem[colorMode]}
      onContextMenu={(event) => {
        event.preventDefault();
        if (!isParent) {
          actions({ Name: name, Id: id });
        }
      }}
    >
      <Flex>
        <HStack>
          {isParent === true ? (
            <>
              <Box height="70px"></Box>
              <img
                width="30px"
                src={getAnnotationIcon(annotationType, finalVersion)}
                alt="Images"
                title={
                  modifiedBy != null
                    ? `Modified by ${modifiedBy.split("@")[0]} on ${changedOn}`
                    : ""
                }
              />
            </>
          ) : (
            <>
              <Box
                width="8px"
                height="70px"
                bg={status === "Add" ? "green.400" : "red.400"}
                title={`Modified by ${
                  modifiedBy.split("@")[0]
                } on ${changedOn}`}
              ></Box>
              <img
                width="30px"
                src={getAnnotationIcon(annotationType, 1)}
                alt="Images"
              />
            </>
          )}

          <Stack color={textColor[colorMode]} spacing={1} >
            <Text
              fontSize="sm"
              isTruncated
              title={isParent ? parentPath.split(" | ").join("\n") : name}
            >
              {name}
            </Text>

            <Text fontSize="xs" isTruncated>
              {annotationType}
            </Text>
          </Stack>
        </HStack>
      </Flex>
    </Box>
  );
};

export default ProposedRelationItem;
