import React, { useEffect, useRef, useState } from "react";
import { Card } from "../../../../Shared/Common";
import { bgColorBox } from "../../../../../config/Theme";
import { useSelector, shallowEqual } from "react-redux";
import { DqeEditorApi } from "../../../../../api/DqeEditorApi";
import { ToastService } from "../../../../../services/ToastService";
import {
  useDisclosure,
  FormControl,
  Input,
  Grid,
  Box,
  Flex,
  GridItem,
  Stack,
  Heading,
  useColorMode,
  VStack,
  Spacer,
  FormLabel,
  Select,
  Center,
  Button,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { TreeView, processTreeViewItems } from "@progress/kendo-react-treeview";

import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import TreeItem from "../../Components/TreeItem";
import ListSanitizedModalAnnotation from "./ListSanitizedModalAnnotation";
import { createTree, duplicateChecker } from "../../helper";

const TreeModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { listAction, sanitizedAnnotation } = props;
  const annotationTypeAll = useSelector(
    (state) => state.dqeEditor.annotationTypeAll,
    shallowEqual
  );
  const [treeData, setTreeData] = useState([]);
  const [treeAllData, setTreeAllData] = useState([]);

  const userState = useSelector((state) => state.auth, shallowEqual);
  const treeState = useSelector(
    (state) => state.dqeEditor.treeAllData,
    shallowEqual
  );
  useEffect(() => {
    props.onModalTreeOpen(onOpen);
  });

  const [treeItemSelected, setTreeItemSelected] = useState({
    Id: 0,
    Name: "Select Item from Tree",
  });

  const [expand, setExpand] = useState({ ids: [], idField: "Name" });
  const annotationTypeVal = useRef(null);
  const annotationName = useRef(null);
  const parent = useRef(null);
  let parentItem = treeState.filter(
    (item) => item.Id === sanitizedAnnotation.ParentId
  );
  if (parentItem.length > 0) {
    parentItem = parentItem[0];
  }

  const { colorMode } = useColorMode(); // To toggle Darkmode

  // const onExpandChange = (event) => {
  //   let ids = expand.ids.slice();
  //   const index = ids.indexOf(event.item.Name);

  //   index === -1 ? ids.push(event.item.Name) : ids.splice(index, 1);
  //   console.log("ID", ids);

  //   setExpand({ ids, idField: "Name" });
  // };

  const onExpandChange = (event) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.Id);

    if (index === -1) {
      ids.push(event.item.Id);
      setExpand({ ids, idField: "Id" });
      DqeEditorApi.ListSanitizedAnnotationByLevel({
        Id: event.item.Id,
      }).then((data) => {
        if (data.ListSanitizedAnnotationByLevelCollection != null) {
          data = data.ListSanitizedAnnotationByLevelCollection;
          let allData = [];
          if (data.length === 0) {
            const data = JSON.parse(JSON.stringify(treeAllData));
            allData = data.map((item) => {
              if (item.Id === event.item.Id) {
                item["hasChildren"] = false; //DON'T change this
                return item;
              }
              return item;
            });
          } else {
            allData = treeAllData.concat(
              data
                .filter((item) => {
                  if (!event.item.items) {
                    return item;
                  }
                  return !duplicateChecker(event.item.items, item.Id);
                })
                .map((item) => {
                  item["hasChildren"] = true;
                  return item;
                })
            );
          }

          setTreeData(createTree(allData));
          setTreeAllData(allData);
        } else {
          ToastService.errorToast(
            "List Sanitized Annotation By Level Collection Failed",
            data.ApiExecutionInfo.Exception
          );
        }
      });
    } else {
      ids.splice(index, 1);
      setExpand({ ids, idField: "Id" });
    }
  };

  let listSanitizedAnnotationSearchModal = null;

  const updateExistingSanitizedAnnotation = (sanitizedAnnotation) => {
    DqeEditorApi.UpdateExistingSanitizedAnnotation({
      SanitizedAnnotationId: sanitizedAnnotation.Id,
      Name: annotationName.current.value,
      Type: annotationTypeVal.current.value,
      ParentId: parent.current.value === "null" ? null : parent.current.value,
    })
      .then((status) => {
        if (status === true) {
          ToastService.successToast(
            "API",
            "Sanitized Annotation" + annotationName.current.value + " Updated"
          );
        } else {
          ToastService.errorToast(
            "Sanitized Annotation " +
              annotationName.current.value +
              " " +
              " Updation Failed",
            status.Exception
          );
        }
      })
      .finally(() => {
        listAction(0, 0, "RefreshAnnotationListAfterModalClose");
        onClose();
        // listSanitizedAnnotationSearchModal();
      });
  };
  useEffect(() => {
    let expanded = [];
    const getParentIDs = (items, term) => {
      return items.reduce((acc, item) => {
        if (item.items && item.items.length > 0) {
          expanded.push(item.Name);
          let newItems = getParentIDs(item.items, term);
          if (newItems && newItems.length > 0) {
            acc.push({
              ...item,
              items: newItems,
            });
          }
        }
        return acc;
      }, []);
    };

    getParentIDs(treeData);

    setExpand({ ids: expanded, idField: "Name" });
  }, [treeData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      scrollBehavior="outside"
    >
      <ModalOverlay />
      <ModalContent w="900px" h="90vh">
        <ModalHeader h="8px">Update sanitized Annotation</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={2}>
          <Box
            m={2}
            pt={2}
            d="flex"
            // maxW="1600"
            flexDirection="row"
            justifyContent="center"
          >
            <Grid h="80vh" templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem maxW={370}>
                <Card bg={bgColorBox[colorMode]}>
                  <Stack minWidth={350} h="75vh" spacing={3}>
                    <Heading size="sm">Tree</Heading>
                    <Stack spacing={3}>
                      <ListSanitizedModalAnnotation
                        setTreeData={setTreeData}
                        setTreeAllData={setTreeAllData}
                        listSanitizedAnnotationSearchModal={(ref) =>
                          (listSanitizedAnnotationSearchModal = ref)
                        }
                      />
                    </Stack>
                    <Flex
                      wrap
                      justify="start"
                      height="60vh"
                      overflow="auto"
                      paddingTop={5}
                    >
                      <TreeView
                        className="tree-scroll"
                        data={processTreeViewItems(treeData, {
                          expand,
                        })}
                        draggable={true}
                        expandIcons={true}
                        onExpandChange={onExpandChange}
                        onItemClick={(event) => setTreeItemSelected(event.item)}
                        item={(props) => [
                          <TreeItem
                            key={`tree-item-${props.item.Id}`}
                            title={props.item.Name}
                            type={props.item.Type}
                            isFinal={Number(props.item.FinalVersion)}
                            Count={props.item.Count}
                            hierarchicalIndex={props.itemHierarchicalIndex}
                            parentPath={props.item.ParentPath}
                            listAction={listAction}
                            id={props.item.Id}
                            finalVersion={Number(props.item.FinalVersion)}
                            userName={userState.userName}
                            modifiedBy={props.item.ModifiedBy}
                            statusChangedOn={props.item.StatusChangedOn}
                          />,
                        ]}
                      />
                    </Flex>
                  </Stack>
                </Card>
              </GridItem>
              <GridItem maxW={370}>
                <Card bg={bgColorBox[colorMode]}>
                  <VStack minWidth={350} h="75vh" spacing={30}>
                    <Box h="300px" w="300px" mt={2}>
                      <FormControl mt={4}>
                        <Center>
                          <FormLabel>Sanitized Annotation Name</FormLabel>
                        </Center>
                        <Input
                          my={2}
                          placeholder="Annotation"
                          defaultValue={sanitizedAnnotation.Name}
                          ref={annotationName}
                        />
                      </FormControl>

                      <FormControl>
                        <Center>
                          <FormLabel>Sanitized Annotation Type</FormLabel>
                        </Center>
                        <Select
                          my={2}
                          defaultValue={sanitizedAnnotation.Type}
                          ref={annotationTypeVal}
                        >
                          {annotationTypeAll.map((annotation, index) => (
                            <option
                              title={annotation.Description}
                              key={`annotation-type-${index}`}
                              value={annotation.Name}
                            >
                              {annotation.Name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <Center>
                          <FormLabel>Parent</FormLabel>
                        </Center>
                        <Select
                          my={2}
                          defaultValue={parentItem.Name}
                          ref={parent}
                        >
                          <option value={parentItem.Id}>
                            {parentItem.Name}
                          </option>
                          <option value="null">
                            Root Sanitized Annotation
                          </option>
                          <option value={treeItemSelected.Id}>
                            {treeItemSelected.Name}
                          </option>
                        </Select>
                      </FormControl>
                    </Box>
                    <Spacer />
                    <Stack direction="row">
                      <Button
                        leftIcon={<CheckIcon />}
                        colorScheme="blue"
                        variant="solid"
                        onClick={() =>
                          updateExistingSanitizedAnnotation(sanitizedAnnotation)
                        }
                      >
                        Update
                      </Button>
                      <Button
                        leftIcon={<CloseIcon />}
                        colorScheme="blue"
                        variant="outline"
                        // onClick={() => listAction(3, 0, "ClearAnnotationList")}
                      >
                        Clear
                      </Button>
                    </Stack>
                  </VStack>
                </Card>
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TreeModal;
