import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import AdminRouter from "./AdminRouter.js";
import Navbar from "../Shared/Navbar";
import LocalStorageService from "../../services/LocalStorageService.js";
import { roleID } from "../../data/Data.js";

const Admin = () => {
  const authState = useSelector((state) => state.auth);

  const history = useHistory();
  let { url, path } = useRouteMatch();

  useEffect(() => {
    if (authState.authenticated) {
      for (const role of authState.roleMembership) {
        if (role.RoleID === roleID[0] && !role.IsBlockedForThatRole) {
          history.push(`${url}/workBoard`);
          return;
        }
      }
    }
    LocalStorageService.clearToken();
    history.push("/");
  }, []);
  return (
    <>
      <Navbar path={path} />
      <AdminRouter path={path} />
    </>
  );
};

export default Admin;
