import {
  Flex,
  useColorMode,
  IconButton,
  Box,
  Text,
  Spacer,
  Button,
  HStack,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { bgColor, textColor } from "../../config/Theme";
import Profile from "./Profile";
import LocalStorageService from "../../services/LocalStorageService";
import { useHistory } from "react-router-dom";
import { appVersion, roleID, roleRoutes } from "../../data/Data";
import { useSelector } from "react-redux";

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      w="100vw"
      as="nav"
      bg={bgColor[colorMode]}
      color={textColor[colorMode]}
      justify="flex-start"
      fontSize={["md", "lg", "xl", "xl"]}
      boxShadow="md"
      p={2}
      mb={2}
    >
      <Flex w="100vw" justify="space-between" mx={8}>
        <Box>
          <Text fontSize="3xl"> SynerAI</Text>
        </Box>
        <Spacer />

        <HStack>
          <Text> {appVersion}</Text>
          <NavButtons />
          <IconButton
            rounded="full"
            onClick={toggleColorMode}
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            mx={4}
          >
            Change Color Mode
          </IconButton>
          <Profile />
        </HStack>
      </Flex>
    </Flex>
  );
};

const NavButtons = () => {
  const history = useHistory();

  const profileState = useSelector((state) => state.auth);
  const handleNavigationClick = (event, role) => {
    event.preventDefault();
    LocalStorageService.setAccessRole(role);
    history.push("/");
  };
  return roleID.map((role) => {
    if (
      role !== LocalStorageService.getAccessRole() &&
      profileState.roleMembership.some(
        (membership) => membership.RoleID === role
      )
    ) {
      return (
        <Button
          variant="ghost"
          onClick={(event) => handleNavigationClick(event, role)}
        >
          {roleRoutes[role]}
        </Button>
      );
    }
  });
};

export default Navbar;
