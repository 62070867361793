import axios from "axios";
import { LocalStorageService } from "../services/LocalStorageService";
import { getAccessToken } from "../services/AuthService";
import { config } from "../config/Config";

axios.defaults.baseURL = "https://synerai-internal-apis.azurewebsites.net/api";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("Axios Error 401");
    const originalRequest = error.config;

    // if (
    //   error.response.status === 401) {
    //   router.push("/login");
    //   return Promise.reject(error);
    // }

    try {
      if (error.response.status === 401) {
        originalRequest._retry = true;
        getAccessToken(config.scopes).then((refreshToken) => {
          if (refreshToken != null) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + LocalStorageService.getAccessToken();
            return axios(originalRequest);
          }
        });
      }
    } catch (error) {
      //TODO
      console.log("redirect");
    }
    return Promise.reject(error);
  }
);
