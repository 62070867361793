import axios from "axios";
import { ToastService } from "../services/ToastService";

export const AdminEditorApi = {
  ListProposedSanitizedAnnotations: async (searchParameters) => {
    const response = await axios.post(
      "ListProposedSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data.ListProposedSanitizedAnnotationCollection;
      } else {
        ToastService.errorToast(
          "List Proposed Sanitized Annotation",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  ListProposedSanitizedAnnotationRelation: async (searchParameters) => {
    const response = await axios.post(
      "ListProposedAnnotationSanitizedAnnotationRelation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data
          .ListProposedAnnotationSanitizedAnnotationRelationCollection;
      } else {
        ToastService.errorToast(
          "List Proposed Sanitized Annotation Relation",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  ListSanitizedAnnotationsForAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "ListSanitizedAnnotationsForAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data.ListSanitizedAnnotationsForAnnotationCollection;
      } else {
        ToastService.errorToast(
          "Sanitized Annotation for Annotaion",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Auth error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },

  ApproveProposedSanitizedAnnotationBulk: async (searchParameters) => {
    const response = await axios.post(
      "ApproveProposedSanitizedAnnotationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        ToastService.successToast(
          "Approve Proposed SanitizedAnnotation",
          "Success"
        );
      } else {
        ToastService.errorToast(
          "Approve Proposed Sanitized Annotation",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Auth error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },

  DeclineProposedSanitizedAnnotationBulk: async (searchParameters) => {
    const response = await axios.post(
      "DeclineProposedSanitizedAnnotationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        ToastService.successToast(
          "Decline Proposed SanitizedAnnotation",
          "Success"
        );
      } else {
        ToastService.errorToast(
          "Decline Proposed Sanitized Annotation",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Decline Proposed Sanitized Annotation error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  ApproveAnnotationSanitizedAnnotationRelationBulk: async (
    searchParameters
  ) => {
    const response = await axios.post(
      "ApproveAnnotationSanitizedAnnotationRelationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        ToastService.successToast(
          "Approve Annotation Sanitized Annotation Relation",
          "Success"
        );
      } else {
        ToastService.errorToast(
          "Approve Annotation Sanitized Annotation Relation",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Approve Annotation Sanitized Annotation Relation error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  DeclineAnnotationSanitizedAnnotationRelationBulk: async (
    searchParameters
  ) => {
    const response = await axios.post(
      "DeclineAnnotationSanitizedAnnotationRelationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        ToastService.successToast(
          "Decline Annotation Sanitized Annotation Relation",
          "Success"
        );
      } else {
        ToastService.errorToast(
          "Decline Annotation Sanitized Annotation Relation",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("Decline Annotation Sanitized Annotation Relation error", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
};
