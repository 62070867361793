import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  useColorMode,
  Heading,
  Button,
  Center,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Card } from "../Shared/Common";

import {
  setAuthenticated,
  setUser,
  setRoleMembership,
} from "../../slice/AuthSlice";
import { getAccounts, getUserDetails, login } from "../../services/AuthService";
import { appVersion, roleID, roleRoutes } from "../../data/Data.js";
import LocalStorageService from "../../services/LocalStorageService";

const Auth = (props) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatchActions = async (status) => {
    if (status) {
      const {
        authenticated,
        userName,
        fullName,
        isBlocked,
        userRoles,
      } = await getUserDetails();

      dispatch(setAuthenticated({ authenticated: authenticated }));
      dispatch(setUser({ userName, fullName, isBlocked }));
      dispatch(setRoleMembership({ roles: userRoles }));

      const currentRole = LocalStorageService.getAccessRole();
      if (currentRole && currentRole.length === 36) {
        for (let index = 0; index < userRoles.length; index++) {
          const role = userRoles[index];
          if (currentRole === role.RoleID && !role.IsBlockedForThatRole) {
            props.history.push(roleRoutes[role.RoleID]);
            return;
          }
        }
        LocalStorageService.clearAccessRole();
      }

      for (const ROLE of roleID) {
        for (let index = 0; index < userRoles.length; index++) {
          const role = userRoles[index];
          if (ROLE === role.RoleID && !role.IsBlockedForThatRole) {
            props.history.push(roleRoutes[role.RoleID]);
            LocalStorageService.setAccessRole(ROLE);
            return;
          }
        }
      }
    } else {
      dispatch(setAuthenticated({ authenticated: false }));
    }
  };

  useEffect(() => {
    const accounts = getAccounts();
    if (accounts && accounts.length > 0) {
      console.log("user already");
      setIsLoggedIn(true);
      dispatchActions(true);
    }
  }, []);

  const loginHandler = () => {
    login().then((status) => {
      dispatchActions(status);
    });
  };

  return (
    <Center h="100vh">
      <Card borderWidth={1} width="350px" textAlign="center" bg={colorMode}>
        <Flex paddingTop={4}>
          <Spacer />
          <Heading alignSelf="center" as="h1">
            SynerAI
          </Heading>
          <Spacer />
          <Box textAlign="right" py={4}>
            <IconButton
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
              variant="ghost"
            />
          </Box>
        </Flex>
        <Box p={4}>
          <Box my={8} textAlign="left">
            <Button
              disabled={isLoggedIn}
              bgColor="purple.500"
              width="full"
              mt={4}
              onClick={loginHandler}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Box textAlign="right">{appVersion}</Box>
      </Card>
    </Center>
  );
};
export default Auth;
