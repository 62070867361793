export const config = {
  appId: "2405c2b2-468a-4707-81b1-05108d1b2396",
  tenantId: "20909596-1920-462f-9f8c-7dffea8398ca",
  redirectUri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://dqa-tool.synerai.app",
  authority:
    "https://login.microsoftonline.com/20909596-1920-462f-9f8c-7dffea8398ca",
  scopes: [
    "https://synerai-internal-apis.azurewebsites.net/user_impersonation",
  ],
};
