import axios from "axios";

export const DqeEditorApi = {
  ListSanitizedAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "ListSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data;
      } else {
        console.log("ListSanitizedAnnotation ", response.data.ApiExecutionInfo);
        return response.data;
      }
    }
  },
  LookUpAnnotations: async (searchParameters) => {
    const response = await axios.post("LookUpAnnotations?", searchParameters);

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data;
      } else {
        console.log("LookUpAnnotations", response.data.ApiExecutionInfo);
        return response.data;
      }
    }
  },
  ListAnnotationsForSanitizedAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "ListAnnotationsForSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data;
      } else {
        console.log("ListAnnotationsForSanitizedAnnotation", response.data.ApiExecutionInfo);
        return response.data;
      }
    }
  },
  ListSanitizedAnnotationByLevel: async (searchParameters) => {
    const response = await axios.post(
      "ListSanitizedAnnotationByLevel?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data;
      } else {
        console.log("ListSanitizedAnnotationByLevel", response.data.ApiExecutionInfo);
        return response.data;
      }
    }
  },

  AddNewAnnotationSanitizedAnnotationRelationBulk: async (searchParameters) => {
    const response = await axios.post(
      "AddNewAnnotationSanitizedAnnotationRelationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        console.log("Success", response.data.ApiExecutionInfo);

        return true;
      } else {
        console.log("AddNewAnnotationSanitizedAnnotationRelationBulk", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
  RemoveAnnotationSanitizedAnnotationRelationBulk: async (searchParameters) => {
    const response = await axios.post(
      "RemoveAnnotationSanitizedAnnotationRelationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return true;
      } else {
        console.log("RemoveAnnotationSanitizedAnnotationRelationBulk", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
  CancelAnnotationSanitizedAnnotationRelationBulk: async (searchParameters) => {
    const response = await axios.post(
      "CancelAnnotationSanitizedAnnotationRelationBulk?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return true;
      } else {
        console.log("CancelAnnotationSanitizedAnnotationRelationBulk", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
  CreateNewSanitizedAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "CreateNewSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return true;
      } else {
        console.log("CreateNewSanitizedAnnotation", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
  ListSanitizedAnnotationsForAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "ListSanitizedAnnotationsForAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data;
      } else {
        console.log("ListSanitizedAnnotationsForAnnotation", response.data.ApiExecutionInfo);
        return response.data;
      }
    }
  },
  UpdateExistingSanitizedAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "UpdateExistingSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return true;
      } else {
        console.log("UpdateExistingSanitizedAnnotation", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
  CancelProposedSanitizedAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "CancelProposedSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return true;
      } else {
        console.log("CancelProposedSanitizedAnnotation", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
  DeleteExistingSanitizedAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "DeleteExistingSanitizedAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return true;
      } else {
        console.log("DeleteExistingSanitizedAnnotation", response.data.ApiExecutionInfo);
        return response.data.ApiExecutionInfo;
      }
    }
  },
};
