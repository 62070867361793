const createTree = (items, Id = null) => {
  return items
    .filter((item) => item.ParentId === Id)
    .map((item) => {
      const data = createTree(items, item.Id);
      if (data.length === 0) {
        return { ...item };
      } else {
        return { ...item, items: data };
      }
    });
};

export const createSanitizedAnnotationTree = (data) => {
  data = data.map((parent) => {
    parent.ParentId = null;
    return parent;
  });

  data.forEach((parent) => {
    data.forEach((child, cIndex) => {
      if (
        child.ParentPath.includes(parent.ParentPath) &&
        child.Id !== parent.Id
      ) {
        data[cIndex].ParentId = parent.Id;
      }
    });
  });

  return createTree(data);
};

export const getUnSanitizedAnnotations = (data) => {
  const newData = data.filter((ver) => ver.FinalVersion === 0);
  return newData;
};

export const getNextDate = (currentDate) => {
  let d = new Date(currentDate);
  d.setUTCDate(d.getUTCDate() + 1);
  return d.toISOString();
};

export const SEPARATOR = "_";
export const bgColors = ["#d0e8f2", "#93abd3", "#9ad3bc"];

export const getBackgroundColor = (hierarchicalIndex) => {
  const pos = hierarchicalIndex.split(SEPARATOR).length + 2;
  return bgColors[pos % 3];
};
