export const SEPARATOR = "_";
export const bgColors = ["#d0e8f2", "#93abd3", "#9ad3bc"];

export const dropItemData = {
  Id: "0000000000000000000",
  Name: "Drop Here",
};

export const getBackgroundColor = (hierarchicalIndex) => {
  const pos = hierarchicalIndex.split(SEPARATOR).length + 2;
  return bgColors[pos % 3];
};

export const search = (items, term) => {
  return items.reduce((acc, item) => {
    if (contains(item.Name, term)) {
      let newItem = Object.assign({ expanded: true }, item);
      acc.push(newItem);
    } else if (item.items && item.items.length > 0) {
      let newItems = search(item.items, term);
      if (newItems && newItems.length > 0) {
        acc.push({
          ...item,
          items: newItems,
          expanded: true,
        });
      }
    }
    return acc;
  }, []);
};

export const contains = (text, term) => {
  if (term === "") {
    return false;
  }
  return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
};

export const createTree = (items, Id = null) => {
  return items
    .filter((item) => item.ParentId === Id)
    .map((item) => {
      const data = createTree(items, item.Id);
      if (data.length === 0) {
        return { ...item };
      } else {
        return { ...item, items: data };
      }
    });
};

export class LogItem {
  constructor(operation, action, data) {
    this.operation = operation;
    this.action = action;
    this.data = data;
  }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const duplicateChecker = (data, nodeId) => {
  if (nodeId) {
    return data.some((x) => x.Id.indexOf(nodeId) > -1);
  }
};

export const checkItemAdded = (data) => {
  return data.some((x) =>
    x.Action !== undefined
      ? x.Action.indexOf("Added") > -1
      : console.log("undefinded")
  );
};

export const changedItems = (treeDataState, treeIndex) => {
  const newlyAdded = treeDataState[treeIndex].filter(
    (child) => child.Action === "Added"
  );
  return newlyAdded;
};

export const modifiedItems = (dataList) => {
  return dataList.filter(
    (child) => child.Action === "Added" || child.Action === "Removed"
  );
};

export const getParentIDs = (items) => {
  return items.reduce((acc, item) => {
    acc.push(item.Id);
    if (item.items && item.items.length > 0) {
      let newItems = getParentIDs(item.items);
      if (newItems && newItems.length > 0) {
        acc.push(item.Id);
      }
    }
    return acc;
  }, []);
};
