import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  TreeView,
  TreeViewDragClue,
  moveTreeViewItem,
  processTreeViewItems,
  TreeViewDragAnalyzer,
} from "@progress/kendo-react-treeview";
import { Card } from "../../Shared/Common";
import {
  Heading,
  VStack,
  useColorMode,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Stack,
  IconButton,
  Text,
  Tooltip,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, CloseIcon, RepeatIcon } from "@chakra-ui/icons";

import { bgColorBox, bgColorItem } from "../../../config/Theme";

import {
  setAnnotationType,
  setAnnotationTypeAll,
  setTreeAllData,
  setTreeData,
} from "../../../slice/DqeEditorSlice";
import { ToastService } from "../../../services/ToastService";
import {
  dropItemData,
  LogItem,
  duplicateChecker,
  modifiedItems,
  numberWithCommas,
  createTree,
} from "./helper";
import { DqeEditorApi } from "../../../api/DqeEditorApi";
import { GeneralApi } from "../../../api/GeneralApi";

import ListSanitizedAnnotation from "./ListSanitizedAnnotation/ListSanitizedAnnotation";
import LookUpAnnotations from "./LookUpAnnotations/LookUpAnnotations";

import SanitizedAnnotationItems from "./Components/SanitizedAnnotationItems";
import AnnotationListItem from "./Components/AnnotationListItem";
import TreeItem from "./Components/TreeItem";
import LookUpAnnotationItem from "./Components/LookUpAnnotationItem";

import ModalSentences from "../../Shared/ModalSentences";
import ModalCreateAnnotation from "./Modals/ModalCreateAnnotation/ModalCreateAnnotation";
import TreeModal from "./Modals/TreeModal/TreeModal";
import ModalDataChangeAlert from "./Modals/ModalDataChangeAlert/ModalDataChangeAlert";

import { getAnnotationIcon } from "../../../assets/annotationIcons";

let treeViewGuidList = ["", "", "", ""];
let dragOverItemIndex = -1;
let dragClue = null;
let LOG = [];

let openModalChangeDataAlert = null;
let onOpenModalCreateSanitizedAnnotation = null;
let onOpenModalSentences = null;
let onOpenModalTree = null;
let lookupAnnotationSearch = null;
let listSanitizedAnnotationSearch = null;

const Editor = () => {
  const dispatch = useDispatch();

  const treeDataState = useSelector(
    (state) => state.dqeEditor.treeData,
    shallowEqual
  );
  const treeAllData = useSelector(
    (state) => state.dqeEditor.treeAllData,
    shallowEqual
  );
  const userState = useSelector((state) => state.auth, shallowEqual);

  const [expand, setExpand] = useState({
    ids: [],
    idField: "Id",
  });
  const [isFreeze, setIsFreeze] = useState(false);
  useEffect(() => {
    let expanded = [];
    const getParentIDs = (items, term) => {
      return items.reduce((acc, item) => {
        if (item.items && item.items.length > 0) {
          expanded.push(item.Id);
          let newItems = getParentIDs(item.items, term);
          if (newItems && newItems.length > 0) {
            acc.push({
              ...item,
              items: newItems,
            });
          }
        }
        return acc;
      }, []);
    };

    getParentIDs(treeDataState[0]);

    setExpand({ ids: expanded, idField: "Id" });
  }, [treeDataState]);

  const { colorMode } = useColorMode();
  const [multiRelationAnnotation, setMultiRelationAnnotation] = useState({
    Name: "Select  Annotation",
    Id: 0,
  });
  const [sanitizedAnnotation, setSanitizedAnnotation] = useState({
    Name: "Select Sanitized Annotation",
    Id: 0,
    ParentPath: "",
  });

  const [lookupAnnotationSelected, setLookupAnnotationSelected] = useState({
    Name: "",
    Id: 0,
  });
  const [annotationListIsLoading, setAnnotationListIsLoading] = useState(false);

  const [
    sanitizedAnnotationListIsLoading,
    setSanitizedAnnotationListIsLoading,
  ] = useState(false);

  const [treeIndex, setTreeIndex] = useState(0);
  //////////////////////////////////////////////////////////////
  const ListAnnotationsForSanitizedAnnotation = (Id) => {
    if (sanitizedAnnotation.Id === 0) {
      setTreeData({
        index: 2,
        value: [],
      });
    } else {
      setAnnotationListIsLoading(true);
      DqeEditorApi.ListAnnotationsForSanitizedAnnotation({
        SanitizedAnnotationId: Id,
      })
        .then((data) => {
          if (data.ListAnnotationsForSanitizedAnnotationCollection != null) {
            let tempData = data.ListAnnotationsForSanitizedAnnotationCollection;
            tempData.unshift(dropItemData);
            return dispatch(
              setTreeData({
                index: 2,
                value: tempData,
              })
            );
          } else {
            ToastService.errorToast(
              "List Annotations for Sanitized Annotations",
              data.ApiExecutionInfo.Exception
            );
          }
        })
        .finally(() => setAnnotationListIsLoading(false));
    }
  };

  useEffect(() => {
    ListAnnotationsForSanitizedAnnotation(sanitizedAnnotation.Id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanitizedAnnotation]);

  /////////////////////////////////////////////////////////////////////////////////////////////
  const ListSanitizedAnnotationsForAnnotation = (Id) => {
    if (multiRelationAnnotation.Id === 0) {
      setTreeData({
        index: 3,
        value: [],
      });
    } else {
      setSanitizedAnnotationListIsLoading(true);
      DqeEditorApi.ListSanitizedAnnotationsForAnnotation({
        AnnotationId: Id,
      })
        .then((data) => {
          if (data.ListSanitizedAnnotationsForAnnotationCollection != null) {
            let tempData = data.ListSanitizedAnnotationsForAnnotationCollection;
            tempData.unshift(dropItemData);
            return dispatch(
              setTreeData({
                index: 3,
                value: tempData,
              })
            );
          } else {
            ToastService.errorToast(
              "List Sanitized Annotations For Annotation Collection Failed",
              data.ApiExecutionInfo.Exception
            );
          }
        })
        .finally(() => setSanitizedAnnotationListIsLoading(false));
    }
  };

  useEffect(() => {
    ListSanitizedAnnotationsForAnnotation(multiRelationAnnotation.Id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiRelationAnnotation]);
  ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    GeneralApi.ListAnnotationType({ ALL: 0 }).then((data) => {
      if (data !== null) {
        dispatch(setAnnotationType({ value: data }));
      }
    });
    GeneralApi.ListAnnotationType({ ALL: 1 }).then((data) => {
      if (data !== null) {
        dispatch(setAnnotationTypeAll({ value: data }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemDragOver = (event) => {
    dragClue.show(
      event.pageY + 10,
      event.pageX,
      event.item.text,
      getClueClassName(event)
    );
  };
  const onItemDragEnd = (event) => {
    dragClue.hide();

    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();

    if (eventAnalyzer.isDropAllowed) {
      const { sourceData, targetData } = moveTreeViewItem(
        event.itemHierarchicalIndex,
        resolveData(event.target.guid),
        eventAnalyzer.getDropOperation(),
        eventAnalyzer.destinationMeta.itemHierarchicalIndex,
        resolveData(eventAnalyzer.destinationMeta.treeViewGuid)
      );

      const dest = eventAnalyzer.destinationMeta.treeViewGuid;
      const source = event.target.guid;

      if (dragOverItemIndex !== "0") {
        ToastService.infoToast(
          "Info",
          "You cannot move items to this location"
        );
        return;
      }

      const { items, ...tempDest } = targetData[0].items[0];

      switch (source) {
        case treeViewGuidList[0]:
          if (dest === treeViewGuidList[3] && dragOverItemIndex === "0") {
            /////////condition check parent item present
            let indices = event.itemHierarchicalIndex
              .split("_")
              .map((item) => Number(item));
            let data = treeDataState[0][indices[0]];
            indices.splice(0, 1);
            for (const index of indices) {
              if (duplicateChecker(treeDataState[3], data.Id)) {
                ToastService.warningToast(
                  "Warning",
                  `Parent Item  ${data.Name} is present You cannot add this one`
                );
                return;
              }
              data = data.items[index];
            }

            tempDest["Action"] = "Added";
            targetData.splice(0, 1, tempDest);
            targetData.splice(0, 0, { Id: "000", Name: "Drop Here" });

            LOG.push(new LogItem("Multi Relation", "Add", tempDest));

            if (duplicateChecker(treeDataState[3], targetData[1].Id)) {
              ToastService.warningToast(
                "Warning",
                "Cannot Add Item, Item already present"
              );
            } else {
              setResolvedData(dest, targetData);
            }
          } else {
            ToastService.infoToast(
              "Info",
              "You cannot move items to this location"
            );
          }
          break;

        case treeViewGuidList[1]:
          if (dest === treeViewGuidList[2]) {
            const tempDest = { ...targetData[0].items[0] };
            tempDest["Action"] = "Added";
            targetData.splice(0, 1, tempDest);
            targetData.splice(0, 0, { Id: "000", Name: "Drop Here" });

            LOG.push(new LogItem("Relation", "Add", tempDest));

            if (duplicateChecker(treeDataState[2], targetData[1].Id)) {
              ToastService.warningToast(
                "Warning",
                "Cannot Add Item, Item already present"
              );
            } else {
              setResolvedData(dest, targetData);
              setResolvedData(source, sourceData);
            }
          } else {
            ToastService.infoToast(
              "Info",
              "You cannot move items to this location"
            );
          }
          break;
        default:
          ToastService.infoToast(
            "Info",
            "You cannot move items to this location"
          );
          break;
      }
    }
  };

  const onItemClick = (event) => {
    console.log("itemClick", event);

    switch (event.target.guid) {
      case treeViewGuidList[1]:
        if (modifiedItems(treeDataState[3]).length !== 0) {
          setTreeIndex(3);
          openModalChangeDataAlert();
        } else {
          setMultiRelationAnnotation(event.item);
        }
        break;

      case treeViewGuidList[0]:
        if (modifiedItems(treeDataState[2]).length !== 0) {
          setTreeIndex(2);
          openModalChangeDataAlert();
        } else {
          setSanitizedAnnotation(event.item);
        }
        break;
      default:
        console.log("Onclick- default");
        break;
    }
  };

  const onExpandChange = (event) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.Id);

    if (index === -1) {
      ids.push(event.item.Id);
      setExpand({ ids, idField: "Id" });
      DqeEditorApi.ListSanitizedAnnotationByLevel({
        Id: event.item.Id,
      }).then((data) => {
        if (data.ListSanitizedAnnotationByLevelCollection != null) {
          data = data.ListSanitizedAnnotationByLevelCollection;
          let allData = [];
          if (data.length === 0) {
            const data = JSON.parse(JSON.stringify(treeAllData));
            allData = data.map((item) => {
              if (item.Id === event.item.Id) {
                item["hasChildren"] = false; //DON'T change this
                return item;
              }
              return item;
            });
          } else {
            allData = treeAllData.concat(
              data
                .filter((item) => {
                  if (!event.item.items) {
                    return item;
                  }
                  return !duplicateChecker(event.item.items, item.Id);
                })
                .map((item) => {
                  item["hasChildren"] = true;
                  return item;
                })
            );
          }
          dispatch(
            setTreeData({
              index: 0,
              value: createTree(allData),
            })
          );
          dispatch(setTreeAllData({ value: allData }));
        } else {
          ToastService.errorToast(
            "List Sanitized Annotation By Level Collection Failed",
            data.ApiExecutionInfo.Exception
          );
        }
      });
    } else {
      ids.splice(index, 1);
      setExpand({ ids, idField: "Id" });
    }
  };

  const getClueClassName = (event) => {
    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
    const { itemHierarchicalIndex: itemIndex, treeViewGuid } =
      eventAnalyzer.destinationMeta;

    dragOverItemIndex = itemIndex;
    if (eventAnalyzer.isDropAllowed) {
      if (treeViewGuid !== treeViewGuidList[0]) {
        if (itemIndex === "0") {
          return "k-i-plus";
        }
      } else {
        return "k-i-plus";
      }
    }
    return "k-i-cancel";
  };
  const resolveData = (treeViewGuid) => {
    return treeDataState[treeViewGuidList.indexOf(treeViewGuid)];
  };

  const setResolvedData = (treeViewGuid, data) => {
    dispatch(
      setTreeData({
        index: treeViewGuidList.indexOf(treeViewGuid),
        value: data,
      })
    );
  };

  const listAction = (treeIndex, itemIndex, action) => {
    let tempData = JSON.parse(JSON.stringify(treeDataState[treeIndex])); //TODO

    switch (action) {
      case "Sentences":
        setLookupAnnotationSelected(tempData[itemIndex]);

        onOpenModalSentences();

        break;
      case "ModalTree":
        onOpenModalTree();
        break;
      case "Proposed":
        setLookupAnnotationSelected(tempData[itemIndex]);

        LOG.push(
          new LogItem("Proposed San", "Created", lookupAnnotationSelected)
        );

        if (sanitizedAnnotation.Name) {
          onOpenModalCreateSanitizedAnnotation();
        }
        break;

      case "TreeCreateAnnotation":
        onOpenModalCreateSanitizedAnnotation();

        break;
      case "cancelPSan":
        DqeEditorApi.CancelProposedSanitizedAnnotation({
          ProposedSanitizedAnnotationId: itemIndex.split("|")[0],
        })
          .then((data) => {
            if (data != null) {
              ToastService.successToast(
                "Cancel Proposed Sanitized Annotation",
                itemIndex.split("|")[1] + " Cancelled"
              );
            } else {
              ToastService.errorToast(
                "Cancel Proposed Sanitized Annotation Failed",
                itemIndex.split("|")[1] + " Is not cancelled",
                data.Exception
              );
            }
          })
          .finally(() =>
            setTimeout(() => {
              listSanitizedAnnotationSearch();
            }, 1000)
          );
        break;

      case "deleteSan":
        DqeEditorApi.DeleteExistingSanitizedAnnotation({
          SanitizedAnnotationId: itemIndex.split("|")[0],
        })
          .then((data) => {
            if (data != null) {
              ToastService.successToast(
                "Delete Proposed Sanitized Annotation",
                itemIndex.split("|")[1] + " deleted"
              );
            } else {
              ToastService.errorToast(
                "Delete Proposed Sanitized Annotation Failed",
                itemIndex.split("|")[1] + " Is not deleted",
                data.Exception
              );
            }
          })
          .finally(() =>
            setTimeout(() => {
              listSanitizedAnnotationSearch();
            }, 1000)
          );
        break;

      case "ClearAnnotationList":
        if (treeIndex === 2) {
          setTimeout(() => {
            ListAnnotationsForSanitizedAnnotation(sanitizedAnnotation.Id);
            // listSanitizedAnnotationSearch();
            lookupAnnotationSearch();
          }, 500);
        } else if (treeIndex === 3) {
          setTimeout(() => {
            ListSanitizedAnnotationsForAnnotation(multiRelationAnnotation.Id);
          }, 500);
        }

        break;

      case "RefreshAnnotationListAfterModalClose":
        listSanitizedAnnotationSearch();
        break;
      case "Removed":
        if (treeIndex === 2) {
          LOG.push(new LogItem("Relation", "Removed", tempData[itemIndex]));
          if (tempData[itemIndex].Action === "Added") {
            tempData.splice(itemIndex, 1);
            lookupAnnotationSearch();
          } else {
            Object.assign(tempData[itemIndex], { Action: action });
          }
          dispatch(setTreeData({ index: treeIndex, value: tempData }));
        } else if (treeIndex === 3) {
          LOG.push(
            new LogItem("Multi Relation", "Removed", tempData[itemIndex])
          );
          if (tempData[itemIndex].Action === "Added") {
            tempData.splice(itemIndex, 1);
          } else {
            Object.assign(tempData[itemIndex], { Action: action });
          }
          dispatch(setTreeData({ index: treeIndex, value: tempData }));
        }

        break;
      case "Submit":
        submitData(treeIndex);

        break;
      default:
        console.error("ListAction - default");

        break;
    }
  };

  const submitData = (treeIndex) => {
    let newlyAdded = [],
      newlyRemoved = [],
      newlyCancel = [];
    let myTasksCount = 0;

    switch (treeIndex) {
      case 2:
        setIsFreeze(true);
        newlyAdded = treeDataState[treeIndex]
          .filter((child) => child.Action === "Added")
          .map((child) => {
            return {
              SanitizedAnnotationId: sanitizedAnnotation.Id,
              AnnotationId: child.Id,
            };
          });
        newlyRemoved = treeDataState[treeIndex]
          .filter(
            (child) => child.Action === "Removed" && child.FinalVersion === true
          )
          .map((child) => {
            return {
              SanitizedAnnotationId: sanitizedAnnotation.Id,
              AnnotationId: child.Id,
            };
          });

        newlyCancel = treeDataState[treeIndex]
          .filter(
            (child) =>
              child.Action === "Removed" && child.FinalVersion === false
          )
          .map((child) => {
            return {
              SanitizedAnnotationId: sanitizedAnnotation.Id,
              AnnotationId: child.Id,
            };
          });

        if (newlyAdded.length !== 0) {
          myTasksCount += 1;
        }
        if (newlyRemoved.length !== 0) {
          myTasksCount += 1;
        }
        if (newlyCancel.length !== 0) {
          myTasksCount += 1;
        }

        if (newlyAdded.length !== 0) {
          DqeEditorApi.AddNewAnnotationSanitizedAnnotationRelationBulk({
            Items: newlyAdded,
          })
            .then((data) => {
              if (data === true) {
                ToastService.successToast(
                  "API",
                  "Added Relation To" + sanitizedAnnotation.Name
                );
              } else {
                const annotID = JSON.parse(
                  data.Context.split("exception:")[1]
                ).AnnotationId;
                var annotData = treeDataState[treeIndex].filter(
                  (annotation) => annotation.Id === annotID
                );

                ToastService.errorToast(
                  "Adding Bulk Sanitized Annotation Relation To  " +
                    sanitizedAnnotation.Name +
                    " Failed, " +
                    annotData[0].Name +
                    " Already Present",
                  data.Exception
                );
              }
            })
            .finally(() => {
              myTasksCount -= 1;
              if (myTasksCount === 0) {
                listAction(2, 0, "ClearAnnotationList");
              }
              setIsFreeze(false);
            });
        }
        if (newlyRemoved.length !== 0) {
          DqeEditorApi.RemoveAnnotationSanitizedAnnotationRelationBulk({
            Items: newlyRemoved,
          })
            .then((data) => {
              if (data === true) {
                ToastService.successToast(
                  "API",
                  "Remove Relation from" + sanitizedAnnotation.Name
                );
              } else {
                // TEST THIS
                const annotID = JSON.parse(
                  data.Context.split("exception:")[1]
                ).AnnotationId;
                var annotData = treeDataState[treeIndex].filter(
                  (annotation) => annotation.Id === annotID
                );

                ToastService.errorToast(
                  "Remove Bulk Sanitized Annotation Relation from " +
                    sanitizedAnnotation.Name +
                    " Failed, " +
                    annotData[0].Name +
                    " Already Removed",
                  data.Exception
                );
              }
            })
            .finally(() => {
              myTasksCount -= 1;
              if (myTasksCount === 0) {
                listAction(2, 0, "ClearAnnotationList");
              }
              setIsFreeze(false);
            });
        }
        if (newlyCancel.length !== 0) {
          DqeEditorApi.CancelAnnotationSanitizedAnnotationRelationBulk({
            Items: newlyCancel,
          })
            .then((data) => {
              if (data === true) {
                ToastService.successToast(
                  "API",
                  "Cancelled Relation from" + sanitizedAnnotation.Name
                );
              } else {
                const annotID = JSON.parse(
                  data.Context.split("exception:")[1]
                ).AnnotationId;
                var annotData = treeDataState[treeIndex].filter(
                  (annotation) => annotation.Id === annotID
                );
                ToastService.errorToast(
                  "Cancelling Bulk Sanitized Annotation Relation from " +
                    sanitizedAnnotation.Name +
                    " Failed, " +
                    annotData[0].Name +
                    " Already Cancelled",
                  data.Exception
                );
              }
            })
            .finally(() => {
              myTasksCount -= 1;
              if (myTasksCount === 0) {
                listAction(2, 0, "ClearAnnotationList");
              }
              setIsFreeze(false);
            });
        }

        break;

      case 3:
        setIsFreeze(true);
        newlyAdded = treeDataState[treeIndex]
          .filter((child) => child.Action === "Added")
          .map((child) => {
            return {
              SanitizedAnnotationId: child.Id,
              AnnotationId: multiRelationAnnotation.Id,
            };
          });

        newlyRemoved = treeDataState[treeIndex]
          .filter(
            (child) =>
              child.Action === "Removed" && child.RelationFinalVersion === true
          )
          .map((child) => {
            return {
              SanitizedAnnotationId: child.Id,
              AnnotationId: multiRelationAnnotation.Id,
            };
          });

        newlyCancel = treeDataState[treeIndex]
          .filter(
            (child) =>
              child.Action === "Removed" && child.RelationFinalVersion === false
          )
          .map((child) => {
            return {
              SanitizedAnnotationId: child.Id,
              AnnotationId: multiRelationAnnotation.Id,
            };
          });

        if (newlyAdded.length !== 0) {
          myTasksCount += 1;
        }
        if (newlyRemoved.length !== 0) {
          myTasksCount += 1;
        }
        if (newlyCancel.length !== 0) {
          myTasksCount += 1;
        }

        if (newlyAdded.length !== 0) {
          DqeEditorApi.AddNewAnnotationSanitizedAnnotationRelationBulk({
            Items: newlyAdded,
          })
            .then((data) => {
              if (data === true) {
                ToastService.successToast(
                  "API",
                  "Added Relation To" + multiRelationAnnotation.Name
                );
              } else {
                const annotID = JSON.parse(
                  data.Context.split("exception:")[1]
                ).SanitizedAnnotationId;
                var annotData = treeDataState[treeIndex].filter(
                  (annotation) => annotation.Id === annotID
                );
                ToastService.errorToast(
                  "Adding Bulk Sanitized Annotation Relation To " +
                    multiRelationAnnotation.Name +
                    " Failed, " +
                    annotData[0].Name +
                    " Already Present",
                  data.Exception
                );
              }
            })
            .finally(() => {
              myTasksCount -= 1;
              if (myTasksCount === 0) {
                listAction(3, 0, "ClearAnnotationList");
              }
              setIsFreeze(false);
            });
        }

        if (newlyRemoved.length !== 0) {
          DqeEditorApi.RemoveAnnotationSanitizedAnnotationRelationBulk({
            Items: newlyRemoved,
          })
            .then((data) => {
              if (data === true) {
                ToastService.successToast(
                  "API",
                  "Removed Sanitized Annotation from" +
                    multiRelationAnnotation.Name
                );
              } else {
                const annotID = JSON.parse(
                  data.Context.split("exception:")[1]
                ).SanitizedAnnotationId;
                var annotData = treeDataState[treeIndex].filter(
                  (annotation) => annotation.Id === annotID
                );
                ToastService.errorToast(
                  "Adding Bulk Sanitized Annotation Relation To " +
                    multiRelationAnnotation.Name +
                    " Failed, " +
                    annotData[0].Name +
                    " Already Removed",
                  data.Exception
                );
              }
            })
            .finally(() => {
              myTasksCount -= 1;
              if (myTasksCount === 0) {
                listAction(3, 0, "ClearAnnotationList");
              }
              setIsFreeze(false);
            });
        }
        if (newlyCancel.length !== 0) {
          DqeEditorApi.CancelAnnotationSanitizedAnnotationRelationBulk({
            Items: newlyCancel,
          })
            .then((data) => {
              if (data === true) {
                ToastService.successToast(
                  "API",
                  "Cancelled Sanitized Annotation from" +
                    multiRelationAnnotation.Name
                );
              } else {
                const annotID = JSON.parse(
                  data.Context.split("exception:")[1]
                ).SanitizedAnnotationId;
                var annotData = treeDataState[treeIndex].filter(
                  (annotation) => annotation.Id === annotID
                );
                ToastService.errorToast(
                  "Adding Bulk Sanitized Annotation Relation To " +
                    multiRelationAnnotation.Name +
                    " Failed, " +
                    annotData[0].Name +
                    " Already Cancelled",
                  data.Exception
                );
              }
            })
            .finally(() => {
              myTasksCount -= 1;
              if (myTasksCount === 0) {
                listAction(3, 0, "ClearAnnotationList");
              }
              setIsFreeze(false);
            });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Box h="86vh" d="flex" maxW={1600} justifyContent="center">
      <ModalCreateAnnotation
        onOpenModalCreateSanitizedAnnotation={(onOpen) =>
          (onOpenModalCreateSanitizedAnnotation = onOpen)
        }
        lookupAnnotation={lookupAnnotationSelected}
        sanitizedAnnotation={sanitizedAnnotation}
        listAction={listAction}
      />
      <ModalSentences
        onModalShowSentences={(onOpen) => (onOpenModalSentences = onOpen)}
        annotation={lookupAnnotationSelected}
      />
      <ModalDataChangeAlert
        onOpenModalChangeDataAlert={(onOpen) =>
          (openModalChangeDataAlert = onOpen)
        }
        listAction={listAction}
        annotation={
          treeIndex === 2 ? sanitizedAnnotation : multiRelationAnnotation
        }
        treeIndex={treeIndex}
        modifiedItems={modifiedItems(treeDataState[treeIndex])}
      />
      <TreeModal
        onModalTreeOpen={(onOpen) => (onOpenModalTree = onOpen)}
        sanitizedAnnotation={sanitizedAnnotation}
        listAction={listAction}
      />

      <HStack h="730px" spacing={3} justifyContent="center" alignSelf="center">
        <Box w={290}>
          <Card bg={bgColorBox[colorMode]}>
            <VStack spacing={3} justifyItems="center" h="80vh">
              <Heading size="sm"> Annotations</Heading>

              {multiRelationAnnotation.Id !== 0 ? (
                <HStack
                  p={1}
                  boxShadow="lg"
                  borderRadius="md"
                  bg={bgColorItem[colorMode]}
                >
                  <img
                    width="30px"
                    src={getAnnotationIcon(multiRelationAnnotation.Type, 1)}
                    alt="Images"
                  />

                  <Stack spacing={1}>
                    <Tooltip label={multiRelationAnnotation.Name}>
                      <Text fontSize="sm" isTruncated w="130px">
                        {multiRelationAnnotation.Name}
                      </Text>
                    </Tooltip>
                    <Text fontSize="xs" isTruncated>
                      {multiRelationAnnotation.Type}
                    </Text>
                    <Text fontSize="xs" isTruncated>
                      {`${numberWithCommas(
                        multiRelationAnnotation.Count
                      )}  sentences`}
                    </Text>
                  </Stack>
                </HStack>
              ) : (
                <Box
                  p={3}
                  m={1}
                  borderRadius="sm"
                  border="1px"
                  borderColor="gray.300"
                  w={200}
                  h="55px"
                  textAlign="center"
                >
                  <Tooltip label={multiRelationAnnotation.Name}>
                    <Text isTruncated> {multiRelationAnnotation.Name}</Text>
                  </Tooltip>
                </Box>
              )}

              <Divider orientation="horizontal" />
              <HStack>
                <Heading size="sm"> Sanitized Annotations</Heading>

                {modifiedItems(treeDataState[3]).length === 0 ? (
                  sanitizedAnnotationListIsLoading ? (
                    <Spinner color="blue.400" thickness="3px" />
                  ) : (
                    <IconButton
                      variant="ghost"
                      colorScheme="blue"
                      onClick={() => {
                        listAction(3, 0, "ClearAnnotationList");
                      }}
                      icon={<RepeatIcon />}
                    />
                  )
                ) : (
                  ""
                )}
              </HStack>

              <Flex
                wrap
                justify="start"
                h="60vh"
                overflow="auto"
                pt={2}
                w="255px"
              >
                <TreeView
                  className="tree-scroll"
                  data={treeDataState[3]}
                  draggable={true}
                  onItemDragOver={onItemDragOver}
                  onItemDragEnd={onItemDragEnd}
                  key={3}
                  ref={(treeView) =>
                    (treeViewGuidList[3] = treeView && treeView.guid)
                  }
                  item={(props) => [
                    <SanitizedAnnotationItems
                      key={`sanitized-list-item-${props.item.Id}`}
                      itemIndex={props.itemHierarchicalIndex}
                      name={props.item.Name}
                      userName={userState.userName}
                      type={props.item.Type}
                      action={props.item.Action}
                      finalVersion={Number(props.item.FinalVersion)}
                      status={props.item.SAnnonAction}
                      modifiedBy={props.item.SAnnonUserName}
                      changedOn={props.item.StatusChangedOn}
                      listAction={listAction}
                      relationFinalVersion={Number(
                        props.item.RelationFinalVersion
                      )}
                      relationStatus={props.item.RelationAction}
                      relationUserName={props.item.RelationUserName}
                      relationStatusChangedOn={
                        props.item.RelationStatusChangedOn
                      }
                      parentPath={props.item.ParentPath}
                    />,
                  ]}
                />
              </Flex>
              {modifiedItems(treeDataState[3]).length > 0 ? (
                <Stack direction="row">
                  <IconButton
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => submitData(3)}
                    icon={<CheckIcon />}
                    isDisabled={isFreeze}
                  >
                    Submit
                  </IconButton>
                  <IconButton
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => listAction(3, 0, "ClearAnnotationList")}
                    icon={<CloseIcon />}
                  >
                    Clear
                  </IconButton>
                </Stack>
              ) : (
                ""
              )}
            </VStack>
          </Card>
        </Box>
        <Box minW={400}>
          <Card bg={bgColorBox[colorMode]}>
            <Stack
              minWidth={350}
              h="80vh"
              spacing={3}
              //  minW="230px"
            >
              <Heading size="sm">List Sanitized Annotation</Heading>

              <ListSanitizedAnnotation
                listSanitizedAnnotationSearch={(ref) =>
                  (listSanitizedAnnotationSearch = ref)
                }
              />
              <Box my="-55">
                <IconButton
                  size="xs"
                  float="right"
                  icon={<AddIcon />}
                  onClick={() => {
                    setLookupAnnotationSelected({
                      Name: "",
                      Id: 0,
                    });
                    onOpenModalCreateSanitizedAnnotation();
                  }}
                />
              </Box>
              <Flex
                wrap
                justify="start"
                height="65vh"
                overflow="auto"
                paddingTop={5}
              >
                <TreeView
                  className="tree-scroll"
                  data={processTreeViewItems(treeDataState[0], {
                    expand,
                  })}
                  draggable={true}
                  onItemDragOver={onItemDragOver}
                  onItemDragEnd={onItemDragEnd}
                  ref={(treeView) =>
                    (treeViewGuidList[0] = treeView && treeView.guid)
                  }
                  expandIcons={true}
                  onExpandChange={onExpandChange}
                  onItemClick={onItemClick}
                  item={(props) => [
                    <TreeItem
                      key={`tree-item-${props.item.Id}`}
                      id={props.item.Id}
                      title={props.item.Name}
                      type={props.item.Type}
                      finalVersion={Number(props.item.FinalVersion)}
                      Count={props.item.Count}
                      userName={userState.userName}
                      modifiedBy={props.item.ModifiedBy}
                      hierarchicalIndex={props.itemHierarchicalIndex}
                      listAction={listAction}
                      parentPath={props.item.ParentPath}
                      statusChangedOn={props.item.StatusChangedOn}
                    />,
                  ]}
                />

                <TreeViewDragClue
                  ref={(newDragClue) => (dragClue = newDragClue)}
                />
              </Flex>
            </Stack>
          </Card>
        </Box>
        <Box w={290}>
          <Card bg={bgColorBox[colorMode]}>
            <Box h="80vh">
              <LookUpAnnotations
                lookUpAnnotationSearch={(ref) => (lookupAnnotationSearch = ref)}
              />
              <Flex
                wrap
                justifyContent="end"
                overflow="auto"
                height="60vh"
                mt={2}
                px={6}
                w={290}
              >
                <TreeView
                  className="tree-scroll-fixed"
                  data={treeDataState[1]}
                  key={1}
                  draggable={true}
                  onItemDragOver={onItemDragOver}
                  onItemDragEnd={onItemDragEnd}
                  ref={(treeView) =>
                    (treeViewGuidList[1] = treeView && treeView.guid)
                  }
                  expandIcons={false}
                  onItemClick={onItemClick}
                  item={(props) => [
                    <LookUpAnnotationItem
                      key={`lookup-annotation-item-${props.item.Id}`}
                      itemIndex={Number(props.itemHierarchicalIndex)}
                      name={props.item.Name}
                      type={props.item.Type}
                      count={props.item.Count}
                      action={props.item.Action}
                      listAction={listAction}
                    />,
                  ]}
                />
              </Flex>
            </Box>
          </Card>
        </Box>
        <Box w={290}>
          <Card bg={bgColorBox[colorMode]}>
            <VStack spacing={3} justify="center" h="80vh">
              <Heading size="sm"> Sanitized Annotations</Heading>

              {sanitizedAnnotation.Id !== 0 ? (
                <HStack
                  p={1}
                  w={200}
                  boxShadow="lg"
                  borderRadius="md"
                  bg={bgColorItem[colorMode]}
                  title={sanitizedAnnotation.Id}
                  onClick={() => {
                    navigator.clipboard.writeText(sanitizedAnnotation.Id);
                  }}
                >
                  <img
                    width="30px"
                    src={getAnnotationIcon(
                      sanitizedAnnotation.Type,
                      Number(sanitizedAnnotation.FinalVersion)
                    )}
                    alt="tree-Images"
                    title={
                      sanitizedAnnotation.ModifiedBy == null
                        ? ""
                        : `Modified by ${
                            sanitizedAnnotation.ModifiedBy.split("@")[0]
                          } on ${sanitizedAnnotation.StatusChangedOn}`
                    }
                  />

                  <Stack direction="column" spacing={0.5}>
                    <Text
                      isTruncated
                      w={36}
                      title={sanitizedAnnotation.ParentPath.split(" | ").join(
                        "\n"
                      )}
                    >
                      {sanitizedAnnotation.Name}
                    </Text>

                    <Text fontSize="xs" isTruncated>
                      {sanitizedAnnotation.Type}
                    </Text>
                    <Text fontSize="xs" isTruncated>
                      {`${sanitizedAnnotation.Count} annotations`}
                    </Text>
                  </Stack>
                </HStack>
              ) : (
                <Box
                  p={3}
                  m={1}
                  borderRadius="sm"
                  border="1px"
                  borderColor="gray.300"
                  w={200}
                  h="55px"
                  textAlign="center"
                >
                  {" "}
                  <Tooltip label={sanitizedAnnotation.Name}>
                    <Text isTruncated> {sanitizedAnnotation.Name}</Text>
                  </Tooltip>
                </Box>
              )}

              <Divider orientation="horizontal" />

              <HStack>
                <Heading size="sm"> Annotations</Heading>

                {modifiedItems(treeDataState[2]).length === 0 ? (
                  annotationListIsLoading ? (
                    <Spinner color="blue.400" thickness="2px" />
                  ) : (
                    <IconButton
                      variant="ghost"
                      colorScheme="blue"
                      onClick={() => {
                        listAction(2, 0, "ClearAnnotationList");
                      }}
                      icon={<RepeatIcon />}
                    />
                  )
                ) : (
                  ""
                )}
              </HStack>

              <Flex
                wrap
                justify="start"
                overflow="auto"
                height="60vh"
                pt={2}
                w="245px"
              >
                <TreeView
                  className="tree-scroll"
                  key={2}
                  data={treeDataState[2]}
                  draggable={true}
                  onItemDragOver={onItemDragOver}
                  onItemDragEnd={onItemDragEnd}
                  ref={(treeView) =>
                    (treeViewGuidList[2] = treeView && treeView.guid)
                  }
                  item={(props) => [
                    <AnnotationListItem
                      key={`annotation-list-item-${props.item.Id}`}
                      itemIndex={Number(props.itemHierarchicalIndex)}
                      name={props.item.Name}
                      userName={userState.userName}
                      type={props.item.Type}
                      action={props.item.Action}
                      finalVersion={Number(props.item.FinalVersion)}
                      status={props.item.Status}
                      modifiedBy={props.item.ModifiedBy}
                      statusChangedOn={props.item.StatusChangedOn}
                      listAction={listAction}
                    />,
                  ]}
                />
              </Flex>
              {modifiedItems(treeDataState[2]).length > 0 ? (
                <Stack direction="row">
                  <IconButton
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => submitData(2)}
                    icon={<CheckIcon />}
                    isDisabled={isFreeze}
                  >
                    Submit
                  </IconButton>
                  <IconButton
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => listAction(2, 0, "ClearAnnotationList")}
                    icon={<CloseIcon />}
                  >
                    Clear
                  </IconButton>
                </Stack>
              ) : (
                ""
              )}
            </VStack>
          </Card>
        </Box>

        <Box minW={200} display="none">
          <Card bg={bgColorBox[colorMode]}>
            <VStack spacing={3} justify="center" h="80vh">
              <Heading size="sm">Work Queue</Heading>

              <Divider orientation="horizontal" />
            </VStack>
          </Card>
        </Box>
      </HStack>
    </Box>
  );
};

export default Editor;
