import React, { memo, useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { textColor, bgColorItem } from "../../../../config/Theme";
import { numberWithCommas } from "../helper";
import { getAnnotationIcon } from "../../../../assets/annotationIcons";

const LookUpAnnotationItem = ({ itemIndex, name, type, count, listAction }) => {
  const { colorMode } = useColorMode();
  // console.count("lookup");
  const [closeVisibility, setCloseVisibility] = useState(false);

  return (
    <Box
      pl={4}
      py={1}
      // p={1}
      // m={1}
      borderRadius="sm"
      border="1px"
      borderColor="gray.300"
      w={200}
      color={textColor[colorMode]}
      onMouseEnter={() => setCloseVisibility(true)}
      onMouseLeave={() => setCloseVisibility(false)}
      onContextMenu={(event) => {
        event.preventDefault();
        listAction(1, itemIndex, "Sentences");
      }}
      bg={bgColorItem[colorMode]}
    >
      <HStack>
        {closeVisibility ? (
          <IconButton
            size="xs"
            boxSize="30px"
            icon={<AddIcon />}
            onClick={() => listAction(1, itemIndex, "Proposed")}
          />
        ) : (
          <img width="30px" src={getAnnotationIcon(type, 1)} alt="Images" />
        )}

        <Stack color={textColor[colorMode]} spacing={1}>
          <Tooltip label={name}>
            <Text fontSize="sm" isTruncated w="130px">
              {name}
            </Text>
          </Tooltip>
          <Text fontSize="xs" isTruncated>
            {type}
          </Text>
          <Text fontSize="xs" isTruncated>
            {`${numberWithCommas(count)}  sentences`}
          </Text>
        </Stack>
      </HStack>
    </Box>
  );
};

export default LookUpAnnotationItem;
