import { createSlice } from "@reduxjs/toolkit";
export const DqeEditorSlice = createSlice({
  name: "DqeEditorSlice",
  initialState: {
    treeData: [[], [], [], []],
    treeAllData: [],
    annotationType: [],
    annotationTypeAll: [],
  },
  reducers: {
    setTreeData: (state, action) => {
      state.treeData[action.payload.index] = action.payload.value;
    },
    setTreeAllData: (state, action) => {
      state.treeAllData = action.payload.value;
    },
    setAnnotationType: (state, action) => {
      state.annotationType = action.payload.value;
    },
    setAnnotationTypeAll: (state, action) => {
      state.annotationTypeAll = action.payload.value;
    },
  },
});

export const {
  setTreeData,
  setAnnotationType,
  setAnnotationTypeAll,
  setTreeAllData,
} = DqeEditorSlice.actions;
export default DqeEditorSlice.reducer;
