import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  InputGroup,
  InputLeftAddon,
  useDisclosure,
} from "@chakra-ui/react";

import { FormControl, FormLabel, Input, Button } from "@chakra-ui/react";

const ModalDataChangeAlert = (props) => {
  const { listAction, annotation, treeIndex, modifiedItems } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    props.onOpenModalChangeDataAlert(onOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Warning</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Modified Annotations Present</FormLabel>
            <InputGroup>
              <InputLeftAddon children="Annotation:-" />
              <Input
                placeholder="New Parent"
                isReadOnly
                value={annotation.Name}
              />
            </InputGroup>
          </FormControl>

          <br />
          <Text mb={3} fontWeight="bold">
            Pending changes
          </Text>
          {modifiedItems.map((item, index) => {
            return (
              <Text key={`model-item${index}`} pl={4}>
                {item.Name}
              </Text>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              onClose();
              listAction(treeIndex, 0, "Submit");
              listAction(treeIndex, 0, "ClearAnnotationList");
            }}
          >
            Submit Annotations
          </Button>
          <Button
            onClick={() => {
              onClose();
              listAction(treeIndex, 0, "ClearAnnotationList");
            }}
            mr={3}
            variant="outline"
          >
            Clear
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDataChangeAlert;
