import React from "react";
import { Route, Switch } from "react-router-dom";
import WorkBoard from "./WorkBoard/WorkBoard";

const AdminRouter = ({ path }) => {
  return (
    <Switch>
      <Route path={`${path}/workboard`}>
        <WorkBoard />
      </Route>
    </Switch>
  );
};

export default AdminRouter;
