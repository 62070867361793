import React, { useState } from "react";
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { textColor, bgColorItem } from "../../../../config/Theme";
import { getAnnotationIcon } from "../../../../assets/annotationIcons";

const AnnotationListItem = ({
  itemIndex,
  name,
  userName,
  type,
  finalVersion = 0,
  modifiedBy = userName,
  status = "Add",
  statusChangedOn,
  action = null,
  listAction,
}) => {
  const { colorMode } = useColorMode();
  const [closeVisibility, setCloseVisibility] = useState(false);

  return (
    <Box
      borderRadius="sm"
      border="1px"
      borderColor={
        action == null
          ? "gray.300"
          : action === "Removed"
          ? "red.400"
          : "green.300"
      }
      w={200}
      color={textColor[colorMode]}
      onMouseEnter={() => setCloseVisibility(true)}
      onMouseLeave={() => setCloseVisibility(false)}
      onContextMenu={(event) => {
        event.preventDefault();
        listAction(2, itemIndex, "Sentences");
      }}
      bg={bgColorItem[colorMode]}
    >
      {name === "Drop Here" ? (
        <HStack justify="center" color={textColor[colorMode]} p={3}>
          <AddIcon />
          <Text fontSize="md" isTruncated>
            {name}
          </Text>
        </HStack>
      ) : (
        <Flex>
          <HStack>
            <Box
              width="8px"
              height="60px"
              bg={
                status == null || status === "Success"
                  ? "black"
                  : status === "Add"
                  ? "green.400"
                  : "red.400"
              }
              title={
                modifiedBy == null
                  ? "Approved Relation"
                  : `Modified by ${
                      modifiedBy.split("@")[0]
                    } on ${statusChangedOn}`
              }
            ></Box>

            {finalVersion === 1 ||
            userName.toLowerCase() === modifiedBy.toLowerCase() ? (
              closeVisibility ? (
                <CloseButton
                  size="sm"
                  boxSize="30px"
                  onClick={() => listAction(2, itemIndex, "Removed")}
                />
              ) : (
                <img
                  width="30px"
                  src={getAnnotationIcon(type, 1)}
                  alt="Images"
                />
              )
            ) : (
              <img width="30px" src={getAnnotationIcon(type, 1)} alt="Images" />
            )}

            <Stack color={textColor[colorMode]} spacing={1}>
              <Text fontSize="sm" isTruncated w="120px" title={name}>
                {name}
              </Text>

              <Text fontSize="xs" isTruncated>
                {modifiedBy != null ? `${type}` : type}
              </Text>
            </Stack>
          </HStack>
          {finalVersion === 0 ? (
            <Box
              float="right"
              bg="tomato"
              boxSize="6px"
              borderRadius="lg"
              m={2}
            ></Box>
          ) : (
            ""
          )}
        </Flex>
      )}
    </Box>
  );
};

export default AnnotationListItem;
