import { PublicClientApplication } from "@azure/msal-browser";

import { config } from "../config/Config";
import { getRoleMembership, getUserProfile } from "../api/AuthApi";
import LocalStorageService from "./LocalStorageService";
import { ToastService } from "./ToastService";

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    authority: config.authority,
    redirectUri: config.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});

export const getAccounts = () => {
  return publicClientApplication.getAllAccounts();
}; 

export const login = async () => {
  try {
    // Login via popup
    await publicClientApplication.loginPopup({
      scopes: config.scopes,
      prompt: "select_account",
    });
    return true;
  } catch (err) {
    console.log("Error", normalizeError(err));
    ToastService.errorToast("Login Error", normalizeError(err).message);
    return false;
  }
};

export const logout = () => {
  publicClientApplication.logout();
};

export const getAccessToken = async (scopes) => {
  try {
    const accounts = publicClientApplication.getAllAccounts();
    if (accounts.length <= 0) throw new Error("login_required");
    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    let silentResult = await publicClientApplication.acquireTokenSilent({
      scopes: scopes,
      account: accounts[0],
    });
    LocalStorageService.setToken(silentResult.accessToken);
    return silentResult.accessToken;
  } catch (err) {
    LocalStorageService.clearToken();

    // If a silent request fails, it may be because the user needs
    // to login or grant consent to one or more of the requested scopes
    if (isInteractionRequired(err)) {
      let interactiveResult = await publicClientApplication.acquireTokenPopup({
        scopes: scopes,
      });
      return interactiveResult.accessToken;
    } else {
      throw err;
    }
  }
};
export const getUserDetails = async () => {
  // ToastService.infoToast("Redirecting...", "Will be redirected shortly");

  try {
    let accessToken = await getAccessToken(config.scopes); //just to check the access
    if (accessToken) {
      const { userName, fullName, isBlocked } = await getUserProfile();
      if (!isBlocked) {
        let userRoles = await getRoleMembership();
        if (userRoles !== null) {
          return {
            authenticated: true,
            userName,
            fullName,
            isBlocked,
            userRoles,
          };
        }
      }
    }
  } catch (err) {
    console.log("Error", normalizeError(err));
    ToastService.errorToast("Login Error", normalizeError(err));

    LocalStorageService.clearToken();

    return { authenticated: false };
  }
};

const isInteractionRequired = (error) => {
  if (!error.message || error.message.length <= 0) {
    return false;
  }
  return (
    error.message.indexOf("consent_required") > -1 ||
    error.message.indexOf("interaction_required") > -1 ||
    error.message.indexOf("login_required") > -1 ||
    error.message.indexOf("no_account_in_silent_request") > -1
  );
};

const normalizeError = (error) => {
  var normalizedError = {};
  if (typeof error === "string") {
    var errParts = error.split("|");
    normalizedError =
      errParts.length > 1
        ? { message: errParts[1], debug: errParts[0] }
        : { message: error };
  } else {
    normalizedError = {
      message: error.message,
      debug: JSON.stringify(error),
    };
  }
  return normalizedError;
};
