import React from "react";
import { Route, Switch } from "react-router-dom";
import Editor from "../Dqe/Editor/Editor";

const DqeRouter = ({ path }) => {
  return (
    <Switch>
      <Route path={`${path}/editor`}>
        <Editor />
      </Route>
    </Switch>
  );
};

export default DqeRouter;
