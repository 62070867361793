import React, { useRef, useState } from "react";
import {
  Stack,
  Box,
  Center,
  IconButton,
  Heading,
  Flex,
  Text,
  Spinner,
  HStack,
  Checkbox,
  InputRightElement,
  FormControl,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AdminEditorApi } from "../../../../api/AdminEditorApi";
import { setTreeData } from "../../../../slice/AdminSlice";
import ProposedSanitizedItem from "./ProposedSanitizedItems/ProposedSanitizedItem";
import { CheckIcon, CloseIcon, RepeatIcon } from "@chakra-ui/icons";
import { SearchAutoComplete } from "../../../Shared/SearchAutoComplete";
import { SearchUsers } from "../../../Shared/SearchUsers";
import { getNextDate, createSanitizedAnnotationTree } from "../helper";

const SanitizedAnnotationTree = () => {
  const treeDataState = useSelector((state) => state.admin.treeData);
  const [expand, setExpand] = useState({ ids: [], idField: "Id" });
  const searchTerm = useRef(null);
  const [annotation, setAnnotation] = useState([]);
  const [users, setUsers] = useState([]);
  const [strict, setStrict] = useState(false);
  const [search, setSearch] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const dispatch = useDispatch();
  const [isFreeze, setIsFreeze] = useState(false);

  const [check, setCheck] = useState({
    ids: [],
    applyCheckIndeterminate: true,
    idField: "Id",
  });
  const annotationTypes = useSelector(
    (state) => state.admin.annotationType,
    shallowEqual
  );
  const allUsers = useSelector((state) => state.admin.allUsers, shallowEqual);

  const ListProposedSanitizedAnnotations = () => {
    setSearch(true);
    AdminEditorApi.ListProposedSanitizedAnnotations({
      Name:
        searchTerm.current.value.length === 0 ? null : searchTerm.current.value,
      Type: annotation.length === 0 ? null : annotation.join(";"),
      StrictSearch: strict,
      Users: users.length === 0 ? null : users.join(";"),
      ProposedDateMin:
        dateFrom == null ? null : new Date(dateFrom).toISOString(),
      ProposedDateMax: dateTo == null ? null : getNextDate(dateTo),
    })
      .then((data) => {
        if (data != null) {
          dispatch(
            setTreeData({
              index: 0,
              value: createSanitizedAnnotationTree(data),
            })
          );
        }
      })
      .finally(() => setSearch(false), uncheckAll());
  };

  const declineProposedSanitizedAnnotationBulk = () => {
    setIsFreeze(true);
    const bulkItem = check.ids.map((id) => {
      return {
        ProposedSanitizedAnnotationId: id,
      };
    });
    AdminEditorApi.DeclineProposedSanitizedAnnotationBulk({
      Items: bulkItem,
    }).finally(() => {
      setTimeout(() => {
        ListProposedSanitizedAnnotations();
      }, 1000);
      setIsFreeze(false);
    });
  };

  const approveProposedSanitizedAnnotationBulk = () => {
    setIsFreeze(true);
    const bulkItem = check.ids.map((id) => {
      return {
        ProposedSanitizedAnnotationId: id,
      };
    });
    AdminEditorApi.ApproveProposedSanitizedAnnotationBulk({
      Items: bulkItem,
    }).finally(() => {
      setTimeout(() => {
        ListProposedSanitizedAnnotations();
      }, 1000);
      setIsFreeze(false);
    });
  };

  const onCheckChange = (event) => {
    const settings = {
      singleMode: false,
      checkChildren: true,
      checkParents: false,
    };
    setCheck(
      handleTreeViewCheckChange(event, check, treeDataState[0], settings)
    );
  };

  const onExpandChange = (event) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.Id);
    index === -1 ? ids.push(event.item.Id) : ids.splice(index, 1);

    setExpand({ ids, idField: "Id" });
  };

  const checkAll = () => {
    const checkedIds = {
      ...check,
      ids: treeDataState[0].map((item) => item.Id),
    };
    setCheck(checkedIds);
  };

  const uncheckAll = () => {
    setCheck({ ...check, ids: [] });
  };

  return (
    <div>
      <Heading size="sm" mb={3}>
        Sanitized Annotations
      </Heading>
      <HStack align="start" justifyContent="space-between" w={730}>
        <Stack w={350}>
          <FormControl>
            <InputGroup size="sm">
              <Input
                type="text"
                placeholder="Eg: Mr robot"
                isDisabled={search}
                ref={searchTerm}
                onKeyDown={(event) =>
                  event.key === "Enter"
                    ? ListProposedSanitizedAnnotations()
                    : {}
                }
              />
              <InputRightElement>
                {search ? (
                  <Spinner color="blue.400" thickness="3px" />
                ) : (
                  <IconButton
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                      ListProposedSanitizedAnnotations();
                    }}
                    icon={<RepeatIcon />}
                  />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <HStack w={350} justifyContent="flex-start">
            <SearchAutoComplete
              allItems={annotationTypes}
              onItemSelect={(selectedAnnotations) => {
                setAnnotation(selectedAnnotations);
              }}
            />
            <SearchUsers
              allItems={allUsers}
              onItemSelect={(selectedUsers) => {
                setUsers(selectedUsers);
              }}
            />
          </HStack>
          <Checkbox
            label="strict"
            onChange={(event) => {
              event.preventDefault();
              setStrict(event.target.checked);
            }}
          >
            Strict
          </Checkbox>
        </Stack>
        <Stack>
          <Center>
            <Text>Filter by Date</Text>
          </Center>
          <HStack>
            <InputGroup size="sm">
              <Input
                placeholder="From date"
                type="date"
                defaultValue=""
                value={dateFrom}
                onChange={(event) => setDateFrom(event.target.value)}
              />
            </InputGroup>
            <InputGroup size="sm">
              <Input
                placeholder="To date"
                type="date"
                value={dateTo}
                onChange={(event) => setDateTo(event.target.value)}
              />
            </InputGroup>
          </HStack>
        </Stack>
      </HStack>
      <Flex justifyContent="space-between" w={700} h="57vh">
        {search ? (
          <Box></Box>
        ) : (
          <Box overflow="auto" h="57vh" mt={4}>
            <TreeView
              className="tree-scroll"
              data={processTreeViewItems(
                treeDataState[0],

                { expand: expand, check: check }
              )}
              checkboxes={true}
              onCheckChange={onCheckChange}
              expandIcons={true}
              onExpandChange={onExpandChange}
              item={(props) => [
                <ProposedSanitizedItem
                  key={`proposed-sanitized-list-item-${props.item.Id}`}
                  name={props.item.Name}
                  type={props.item.Type}
                  status={props.item.Status}
                  parentPath={props.item.ParentPath}
                  modifiedBy={props.item.StatusChangedBy}
                  changedOn={props.item.StatusChangedOn}
                  hierarchicalIndex={props.itemHierarchicalIndex}
                />,
              ]}
            />
          </Box>
        )}
      </Flex>
      <br />
      <Center>
        <Stack direction="row">
          <IconButton
            icon={<CheckIcon />}
            colorScheme="blue"
            variant="solid"
            title="Approve Selected Annotations"
            onClick={() => approveProposedSanitizedAnnotationBulk()}
            isDisabled={isFreeze}
          ></IconButton>
          <IconButton
            icon={<CloseIcon />}
            colorScheme="blue"
            variant="outline"
            title="Decline Selected Annotations"
            onClick={() => declineProposedSanitizedAnnotationBulk()}
            isDisabled={isFreeze}
          ></IconButton>

          <Checkbox
            colorScheme="blue"
            onChange={(e) => (e.target.checked ? checkAll() : uncheckAll())}
          >
            All
          </Checkbox>
        </Stack>
      </Center>
    </div>
  );
};

export default SanitizedAnnotationTree;
