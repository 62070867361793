import { Center, Grid, GridItem, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Card } from "../../Shared/Common";
import SanitizedAnnotationTree from "./SanitizedAnnotationTree/SanitizedAnnotationTree";
import RelationTree from "./RelationTree/RelationTree";
import { bgColorBox } from "../../../config/Theme";
import { GeneralApi } from "../../../api/GeneralApi";
import { useDispatch } from "react-redux";
import {
  setAnnotationType,
  setAnnotationTypeAll,
  setAllUsers,
} from "../../../slice/AdminSlice";

const WorkBoard = () => {
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  ///////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    GeneralApi.ListAnnotationType({ ALL: 0 }).then((data) => {
      if (data !== null) {
        dispatch(setAnnotationType({ value: data }));
      }
    });
    GeneralApi.ListAnnotationType({ ALL: 1 }).then((data) => {
      if (data !== null) {
        dispatch(setAnnotationTypeAll({ value: data }));
      }
    });
    GeneralApi.ListUsers().then((data) => {
      if (data !== null) {
        dispatch(setAllUsers({ value: data }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Grid h="600px" templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <Card h="87vh" bg={bgColorBox[colorMode]}>
            <Center>
              <SanitizedAnnotationTree />
            </Center>
          </Card>
        </GridItem>
        <GridItem>
          <Card h="87vh" bg={bgColorBox[colorMode]}>
            <Center>
              <RelationTree />
            </Center>
          </Card>
        </GridItem>
      </Grid>
    </div>
  );
};

export default WorkBoard;
