import React, { memo, useState } from "react";
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { textColor, bgColorItem } from "../../../../config/Theme";
import { getAnnotationIcon } from "../../../../assets/annotationIcons";

const SanitizedAnnotationItems = ({
  itemIndex,
  name,
  userName,
  type,
  finalVersion,
  modifiedBy = userName,
  status = "Add",
  action = null,
  listAction,
  changedOn = new Date().toUTCString(),
  relationFinalVersion,
  relationStatus = "Add",
  relationUserName = userName,
  relationStatusChangedOn = new Date().toUTCString(),
  parentPath,
}) => {
  const { colorMode } = useColorMode();
  const [closeVisibility, setCloseVisibility] = useState(false);

  return (
    <Box
      borderRadius="sm"
      border="1px"
      borderColor={
        action == null
          ? "gray.300"
          : action === "Removed"
          ? "red.400"
          : "green.300"
      }
      w={200}
      color={textColor[colorMode]}
      onMouseEnter={() => setCloseVisibility(true)}
      onMouseLeave={() => setCloseVisibility(false)}
      onContextMenu={(event) => {
        event.preventDefault();
        listAction(3, itemIndex, "Sentences");
      }}
      bg={bgColorItem[colorMode]}
    >
      {name === "Drop Here" ? (
        <HStack justify="center" color={textColor[colorMode]} p={3}>
          <AddIcon />
          <Text fontSize="md" isTruncated>
            {name}
          </Text>
        </HStack>
      ) : (
        <Flex>
          <HStack>
            <Box
              width="8px"
              height="60px"
              bg={
                relationStatus === null || relationStatus === "Success"
                  ? "black"
                  : relationStatus === "Add"
                  ? "green.400"
                  : "red.400"
              }
              title={
                relationUserName !== null
                  ? `Modified by ${
                      relationUserName.split("@")[0]
                    } on ${relationStatusChangedOn}`
                  : "Approved relation"
              }
            ></Box>
            {finalVersion === 1 ||
            userName.toLowerCase() === modifiedBy.toLowerCase() ? (
              closeVisibility ? (
                <CloseButton
                  size="sm"
                  boxSize="30px"
                  onClick={() => listAction(3, itemIndex, "Removed")}
                  title={
                    finalVersion === 0
                      ? `Modified by ${
                          modifiedBy.split("@")[0]
                        } on ${changedOn}`
                      : ""
                  }
                />
              ) : (
                <img
                  width="30px"
                  src={getAnnotationIcon(type, finalVersion)}
                  alt="Images"
                />
              )
            ) : (
              <img
                title={
                  finalVersion === 0
                    ? `Modified by ${modifiedBy.split("@")[0]} on ${changedOn}`
                    : ""
                }
                width="30px"
                src={getAnnotationIcon(type, finalVersion)}
                alt="Images"
              />
            )}

            <Stack color={textColor[colorMode]} spacing={1}>
              <Text
                fontSize="sm"
                isTruncated
                w="120px"
                title={parentPath.split(" | ").join("\n")}
              >
                {name}
              </Text>

              <Text fontSize="xs" isTruncated w="100px" title={type}>
                {modifiedBy != null ? `${type}` : type}
              </Text>
            </Stack>
          </HStack>
          {relationFinalVersion === 0 ? (
            <Box
              float="right"
              bg="tomato"
              boxSize="6px"
              borderRadius="lg"
              m={2}
            ></Box>
          ) : (
            ""
          )}
        </Flex>
      )}
    </Box>
  );
};

export default SanitizedAnnotationItems;
