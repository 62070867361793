import { createSlice } from "@reduxjs/toolkit";
export const AdminSlice = createSlice({
  name: "AdminSlice",
  initialState: {
    treeData: [[], []],
    annotationType: [],
    annotationTypeAll: [],
    allUsers: [],
  },
  reducers: {
    setTreeData: (state, action) => {
      state.treeData[action.payload.index] = action.payload.value;
    },
    setAnnotationType: (state, action) => {
      state.annotationType = action.payload.value;
    },
    setAnnotationTypeAll: (state, action) => {
      state.annotationTypeAll = action.payload.value;
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload.value;
    },
  },
});

export const {
  setTreeData,
  setAnnotationTypeAll,
  setAnnotationType,
  setAllUsers,
} = AdminSlice.actions;
export default AdminSlice.reducer;
