import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  Stack,
  Heading,
  Checkbox,
  FormControl,
  Input,
  InputGroup,
  Spinner,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";

import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import { DqeEditorApi } from "../../../../api/DqeEditorApi";
import { setTreeData } from "../../../../slice/DqeEditorSlice";
import { ToastService } from "../../../../services/ToastService";
import { SearchAutoComplete } from "../../../Shared/SearchAutoComplete";
import { RepeatIcon } from "@chakra-ui/icons";

const LookUpAnnotations = (props) => {
  const dispatch = useDispatch();
  const annotationTypes = useSelector(
    (state) => state.dqeEditor.annotationType,
    shallowEqual
  );
  const searchTerm = useRef(null);
  const [annotation, setAnnotation] = useState([]);
  const [strict, setStrict] = useState(false);
  const [orphan, setOrphan] = useState(true);
  const [rowCount, setRowCount] = useState(100);
  const [search, setSearch] = useState(false);

  const searchApi = () => {
    setSearch(true);
    DqeEditorApi.LookUpAnnotations({
      Name: searchTerm.current.value,
      Type: annotation.length === 0 ? null : annotation.join(";"),
      StrictSearch: strict,
      OrphansOnly: orphan,
      RowCount: rowCount,
    })
      .then((data) => {
        if (data.LookupAnnotationsCollection != null) {
          dispatch(setTreeData({ index: 1, value: data.LookupAnnotationsCollection }));
        } else {
          ToastService.errorToast(
            "Look Up Annotations for " + searchTerm.current.value + " failed",
            data.ApiExecutionInfo.Exception
          );
        }
      })
      .finally(() => setSearch(false));
  };

  useEffect(() => {
    props.lookUpAnnotationSearch(searchApi);
  }, []);

  return (
    <Stack spacing={3}>
      <Heading size="sm">LookUp Annotations</Heading>
      <FormControl>
        <InputGroup size="sm">
          <Input
            type="text"
            placeholder="Eg: Mr robot"
            ref={searchTerm}
            isDisabled={search}
            onKeyDown={(event) => (event.key === "Enter" ? searchApi() : {})}
          />
          <InputRightElement>
            {search ? (
              <Spinner color="blue.400" thickness="3px" />
            ) : (
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {
                  searchApi();
                }}
                icon={<RepeatIcon />}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <SearchAutoComplete
        allItems={annotationTypes}
        onItemSelect={(selectedAnnotations) => {
          setAnnotation(selectedAnnotations);
        }}
      />

      <Stack spacing={3} direction="row">
        <Checkbox
          label="strict"
          onChange={(event) => {
            event.preventDefault();
            setStrict(event.target.checked);
          }}
        >
          Strict
        </Checkbox>
        <Checkbox
          label="orphan"
          defaultChecked={orphan}
          onChange={(event) => {
            event.preventDefault();
            setOrphan(event.target.checked);
          }}
        >
          Orphan
        </Checkbox>
        <NumberInput
          size="xs"
          maxW={16}
          defaultValue={100}
          min={100}
          onChange={(value) => setRowCount(Number(value))}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Stack>
    </Stack>
  );
};

export default LookUpAnnotations;
