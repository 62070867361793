import React, { useRef, useState } from "react";
import {
  Box,
  Center,
  Flex,
  IconButton,
  Stack,
  Text,
  Heading,
  Spinner,
  HStack,
  InputRightElement,
  FormControl,
  InputGroup,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AdminEditorApi } from "../../../../api/AdminEditorApi";
import { setTreeData } from "../../../../slice/AdminSlice";
import ProposedRelationItem from "./ProposedRelationItem/ProposedRelationItem";
import { CheckIcon, CloseIcon, RepeatIcon } from "@chakra-ui/icons";
import { SearchAutoComplete } from "../../../Shared/SearchAutoComplete";
import { SearchUsers } from "../../../Shared/SearchUsers";
import { getNextDate } from "../helper";
import ModalSentences from "../../../Shared/ModalSentences";

const settings = {
  singleMode: false,
  checkChildren: true,
  checkParents: false,
};

const createRelationTree = (treeData) => {
  let finalData = [];
  treeData
    .filter(
      (v, i, a) =>
        a.findIndex(
          (t) => t.SanitizedAnnotationName === v.SanitizedAnnotationName
        ) === i
    )
    .forEach((child) => {
      const annotationList = treeData.filter((annotation) => {
        if (child.SanitizedAnnotationId === annotation.SanitizedAnnotationId) {
          return {
            AnnotationId: annotation.AnnotationId,
            AnnotationName: annotation.AnnotationName,
            AnnotationType: annotation.AnnotationType,
            isParent: false,
            Status: annotation.Status,
            StatusChangedBy: annotation.StatusChangedBy,
            StatusChangedOn: annotation.StatusChangedOn,
          };
        }
      });

      const sanonParent = {
        AnnotationName: child.SanitizedAnnotationName,
        AnnotationId: child.SanitizedAnnotationId,
        AnnotationType: child.SanitizedAnnotationType,
        AnnotationParentPath: child.SanitizedAnnotationParentPath,
        AnnotationFinalVersion: child.SanitizedAnnotationFinalVersion,
        StatusChangedBy: child.SanitizedAnnotationStatusChangedBy,
        StatusChangedOn: child.SanitizedAnnotationStatusChangedOn,
        Status: child.SanitizedAnnotationAction,
        isParent: true,
      };
      sanonParent["items"] = annotationList;
      finalData.push(sanonParent);
    });

  return finalData;
};

let onOpenModalSentences = null;
const RelationTree = () => {
  const dispatch = useDispatch();
  const treeDataState = useSelector((state) => state.admin.treeData);
  const annotationTypes = useSelector(
    (state) => state.admin.annotationType,
    shallowEqual
  );
  const allUsers = useSelector((state) => state.admin.allUsers, shallowEqual);
  const searchTerm = useRef(null);
  const [annotation, setAnnotation] = useState([]);
  const [users, setUsers] = useState([]);
  const [strict, setStrict] = useState(false);
  const [search, setSearch] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [expand, setExpand] = useState({ ids: [], idField: "AnnotationName" });
  const [check, setCheck] = useState({
    ids: [],
    applyCheckIndeterminate: true,
    idField: "AnnotationId",
  });
  const [isFreeze, setIsFreeze] = useState(false);

  const [relationSelected, setRelationSelected] = useState({
    Name: "",
    Id: 0,
  });

  const ListProposedSanitizedAnnotationRelation = () => {
    setSearch(true);

    AdminEditorApi.ListProposedSanitizedAnnotationRelation({
      Name:
        searchTerm.current.value.length === 0 ? null : searchTerm.current.value,
      Type: annotation.length === 0 ? null : annotation.join(";"),
      StrictSearch: strict,
      Users: users.length === 0 ? null : users.join(";"),
      ProposedDateMin:
        dateFrom == null ? null : new Date(dateFrom).toISOString(),
      ProposedDateMax: dateTo == null ? null : getNextDate(dateTo),
    })
      .then((data) => {
        if (data != null) {
          dispatch(setTreeData({ index: 1, value: data }));
        }
      })
      .finally(() => setSearch(false), uncheckAll());
  };

  const filterRelationData = () => {
    const tempTreeData = processTreeViewItems(
      createRelationTree(treeDataState[1]),

      { expand: expand, check: check }
    );
    const bulkItem = [];
    tempTreeData
      .filter(
        (sannon) =>
          sannon.checked === true || sannon.checkIndeterminate === true
      )
      .map((sannon) => {
        const relationList = sannon.items
          .filter((relation) => relation.checked === true)
          .map((relation) => {
            bulkItem.push({
              SanitizedAnnotationId: relation.SanitizedAnnotationId,
              AnnotationId: relation.AnnotationId,
            });
            return {
              SanitizedAnnotationId: relation.SanitizedAnnotationId,
              AnnotationId: relation.AnnotationId,
            };
          });

        return relationList;
      });

    return bulkItem;
  };
  const declineProposedSanitizedAnnotationBulk = () => {
    setIsFreeze(true);
    const bulkItem = filterRelationData();

    AdminEditorApi.DeclineAnnotationSanitizedAnnotationRelationBulk({
      Items: bulkItem,
    }).finally(() => {
      setTimeout(() => {
        ListProposedSanitizedAnnotationRelation();
      }, 1000);
      setIsFreeze(false);
    });
  };

  const approveProposedSanitizedAnnotationBulk = () => {
    setIsFreeze(true);
    const bulkItem = filterRelationData();

    AdminEditorApi.ApproveAnnotationSanitizedAnnotationRelationBulk({
      Items: bulkItem,
    }).finally(() => {
      setTimeout(() => {
        ListProposedSanitizedAnnotationRelation();
      }, 1000);
      setIsFreeze(false);
    });
  };

  const onCheckChange = (event) => {
    setCheck(
      handleTreeViewCheckChange(
        event,
        check,
        createRelationTree(treeDataState[1]),
        settings
      )
    );
  };

  const checkAll = () => {
    const checkedIds = {
      ...check,
      ids: treeDataState[1].map((item) => item.AnnotationId),
    };
    setCheck(checkedIds);
  };

  const uncheckAll = () => {
    setCheck({ ...check, ids: [] });
  };

  const onExpandChange = (event) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.AnnotationName);

    index === -1 ? ids.push(event.item.AnnotationName) : ids.splice(index, 1);

    setExpand({ ids, idField: "AnnotationName" });
  };

  const actions = (relationItem) => {
    setRelationSelected(relationItem);
    onOpenModalSentences();
  };
  return (
    <div>
      <ModalSentences
        onModalShowSentences={(onOpen) => (onOpenModalSentences = onOpen)}
        annotation={relationSelected}
      />
      <Heading size="sm" mb={3}>
        Relations
      </Heading>
      <HStack justifyContent="space-between" align="start" w={730}>
        <Stack w={350}>
          <FormControl>
            <InputGroup size="sm">
              <Input
                type="text"
                placeholder="Eg: Mr robot"
                ref={searchTerm}
                isDisabled={search}
                onKeyDown={(event) =>
                  event.key === "Enter"
                    ? ListProposedSanitizedAnnotationRelation()
                    : {}
                }
              />
              <InputRightElement>
                {search ? (
                  <Spinner color="blue.400" thickness="3px" />
                ) : (
                  <IconButton
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                      ListProposedSanitizedAnnotationRelation();
                    }}
                    icon={<RepeatIcon />}
                  />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <HStack w={350} justifyContent="space-between">
            <SearchAutoComplete
              allItems={annotationTypes}
              onItemSelect={(selectedAnnotations) => {
                setAnnotation(selectedAnnotations);
              }}
            />
            <SearchUsers
              allItems={allUsers}
              onItemSelect={(selectedUsers) => {
                setUsers(selectedUsers);
              }}
            />
          </HStack>
          <Checkbox
            label="strict"
            onChange={(event) => {
              event.preventDefault();
              setStrict(event.target.checked);
            }}
          >
            Strict
          </Checkbox>
        </Stack>
        <Stack>
          <Center>
            <Text>Filter by Date</Text>
          </Center>
          <HStack>
            <InputGroup size="sm">
              <Input
                placeholder="From date"
                type="date"
                defaultValue=""
                value={dateFrom}
                onChange={(event) => setDateFrom(event.target.value)}
              />
            </InputGroup>
            <InputGroup size="sm">
              <Input
                placeholder="To date"
                type="date"
                value={dateTo}
                onChange={(event) => setDateTo(event.target.value)}
              />
            </InputGroup>
          </HStack>
        </Stack>
      </HStack>
      <Flex justifyContent="space-between" w={700} h="57vh">
        {search ? (
          <Box></Box>
        ) : (
          <Box overflow="auto" h="57vh" mt={4}>
            <TreeView
              className="tree-scroll"
              data={processTreeViewItems(
                createRelationTree(treeDataState[1]),

                { expand: expand, check: check }
              )}
              checkboxes={true}
              onCheckChange={onCheckChange}
              expandIcons={true}
              // onItemClick={onItemClick}
              onExpandChange={onExpandChange}
              item={(props) => [
                <ProposedRelationItem
                  key={`proposed-sanitized-list-item-${props.item.AnnotationId}`}
                  id={props.item.AnnotationId}
                  name={props.item.AnnotationName}
                  annotationType={props.item.AnnotationType}
                  status={props.item.Status}
                  finalVersion={Number(props.item.AnnotationFinalVersion)}
                  parentPath={props.item.AnnotationParentPath}
                  modifiedBy={props.item.StatusChangedBy}
                  changedOn={props.item.StatusChangedOn}
                  isParent={props.item.isParent}
                  actions={actions}
                />,
              ]}
            />
          </Box>
        )}
      </Flex>
      <br />
      <Center>
        <Stack direction="row">
          <IconButton
            icon={<CheckIcon />}
            colorScheme="blue"
            variant="solid"
            title="Approve Selected Relations"
            onClick={() => approveProposedSanitizedAnnotationBulk()}
            isDisabled={isFreeze}
          ></IconButton>
          <IconButton
            icon={<CloseIcon />}
            colorScheme="blue"
            variant="outline"
            title="Decline Selected Relations"
            onClick={() => declineProposedSanitizedAnnotationBulk()}
            isDisabled={isFreeze}
          ></IconButton>

          <Checkbox
            colorScheme="blue"
            onChange={(e) => (e.target.checked ? checkAll() : uncheckAll())}
          >
            All
          </Checkbox>
        </Stack>
      </Center>
    </div>
  );
};

export default RelationTree;
