export const LocalStorageService = {
  setToken: (token) => {
    localStorage.setItem("access_token", token);
  },
  getAccessToken: () => {
    return localStorage.getItem("access_token");
  },

  clearToken: () => {
    localStorage.removeItem("access_token");
  },

  setAccessRole: (role) => {
    localStorage.setItem("access_role", role);
  },
  getAccessRole: () => {
    return localStorage.getItem("access_role");
  },
  clearAccessRole: () => {
    localStorage.removeItem("access_role");
  },
};

export default LocalStorageService;
