import React, { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useDisclosure,
} from "@chakra-ui/react";

import { FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { DqeEditorApi } from "../../../../../api/DqeEditorApi";
import { ToastService } from "../../../../../services/ToastService";
import { shallowEqual, useSelector } from "react-redux";

const ModalCreateAnnotation = (props) => {
  const annotationTypeAll = useSelector(
    (state) => state.dqeEditor.annotationTypeAll,
    shallowEqual
  );
  const annotationType = useRef(null);
  const annotationName = useRef(null);
  const parent = useRef(null);
  const { lookupAnnotation, sanitizedAnnotation, listAction } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    props.onOpenModalCreateSanitizedAnnotation(onOpen);
  }, []);

  const creatSanitizedAnnotation = (lookupAnnotation) => {
    DqeEditorApi.CreateNewSanitizedAnnotation({
      Name: annotationName.current.value,
      Type: annotationType.current.value,
      ParentId: parent.current.value === "null" ? null : parent.current.value,
    })
      .then((status) => {
        if (status === true) {
          ToastService.successToast(
            "API",
            "Sanitized Annotation" + annotationName.current.value + " Created"
          );
        } else {
          ToastService.errorToast(
            "Sanitized Annotation " +
              annotationName.current.value +
              " Creation Failed",
            status.Exception
          );
        }
      })
      .finally(() => {
        listAction(2, 0, "ClearAnnotationList");
        onClose();
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Sanitized Annotation</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl mt={4}>
            <FormLabel>Annotation Name</FormLabel>
            <Input
              placeholder="Annotation"
              defaultValue={lookupAnnotation.Name}
              ref={annotationName}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Annotation Type</FormLabel>
            <Select
              defaultValue={
                lookupAnnotation.Id !== 0 ? lookupAnnotation.Type : ""
              }
              ref={annotationType}
              placeholder={
                lookupAnnotation.Id === 0 ? "Select annotation Type" : ""
              }
            >
              {annotationTypeAll.map((annotation, index) => (
                <option
                  title={annotation.Description}
                  key={`annotation-type-${index}`}
                  value={annotation.Name}
                >
                  {annotation.Name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Parent</FormLabel>
            <Select
              defaultValue={
                sanitizedAnnotation.Id === 0 ? "null" : sanitizedAnnotation.Name
              }
              ref={parent}
            >
              {sanitizedAnnotation.Id !== 0 ? (
                <option value={sanitizedAnnotation.Id}>
                  {sanitizedAnnotation.Name}
                </option>
              ) : (
                ""
              )}
              <option value="null">Root Sanitized Annotation</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => creatSanitizedAnnotation(lookupAnnotation)}
          >
            Create
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCreateAnnotation;
