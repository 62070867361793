import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import { VStack, Box, Stack, Heading, Text, Button } from "@chakra-ui/react";
import { GeneralApi } from "../../api/GeneralApi";

const ModalSentences = (props) => {
  const { annotation } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sentences, setSentences] = useState([]);
  const [listOfSentences, setListOfSentences] = useState([]);
  const [articleSelected, setArticleSelected] = useState({
    ArticleId: "",
    SentenceId: "",
    Newsoutlet: "",
    ArticleTitle: "",
  });

  useEffect(() => {
    props.onModalShowSentences(onOpen);
  }, []);

  const openPop = (articleId) => {
    GeneralApi.ListSentencesForArticle({ ArticleId: articleId }).then(
      (data) => {
        setListOfSentences(data);
      }
    );
  };
  useEffect(() => {
    if (isOpen) {
      GeneralApi.ListSentencesForAnnotation({
        AnnotationId: annotation.Id,
        RowCount: 100,
      }).then((data) => {
        console.log("sentences", data);
        setSentences(data);
      });
    }
  }, [annotation, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setArticleSelected({
          ArticleId: "",
          SentenceId: "",
          Newsoutlet: "",
          ArticleTitle: "",
        });
        setListOfSentences([]);
        onClose();
      }}
      size={articleSelected.SentenceId !== "" ? "6xl" : "xl"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sentences mentioning {annotation.Name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack isInline spacing={3}>
            <Box pl={1} py={1}>
              <VStack h="65vh" overflow="auto">
                {sentences.map((sentence, index) => {
                  return (
                    <Box
                      borderRadius="sm"
                      border="1px"
                      borderColor={
                        sentence.ArticleId === articleSelected.ArticleId
                          ? "blue.400"
                          : "gray.300"
                      }
                      p={2}
                      key={`article-sentences-${sentence.ArticleId}-${index}`}
                      onClick={() => {
                        setArticleSelected(sentence);
                        openPop(sentence.ArticleId);
                      }}
                      w={520}
                      mr={1}
                    >
                      <Stack>
                        <HStack>
                          <Heading
                            size="sm"
                            textAlign="center"
                            color="blue.300"
                          >
                            {sentence.Newsoutlet}
                          </Heading>
                          <Spacer />
                          <Text align="right" fontStyle="italic">
                            Sentence # {sentence.SentenceOrder} - Sentiment :{" "}
                            {sentence.SentenceSentimentScore}
                          </Text>
                        </HStack>

                        <Heading size="sm"> {sentence.ArticleTitle}</Heading>

                        {
                          <Text>
                            {sentence.Sentence.split(
                              new RegExp(
                                `(${annotation.Name.replace(
                                  /([.?*+^$[\]\\(){}|-])/g,
                                  "\\$1"
                                )})`,
                                "gi"
                              )
                            ).map((part) =>
                              part.toLowerCase() ===
                              annotation.Name.toLowerCase() ? (
                                <span
                                  style={{
                                    backgroundColor: "orange",
                                  }}
                                >
                                  {part}
                                </span>
                              ) : (
                                part
                              )
                            )}
                          </Text>
                        }
                      </Stack>
                    </Box>
                  );
                })}
              </VStack>
            </Box>
            {listOfSentences.length > 0 ? (
              <Box pl={1} py={1}>
                <Stack spacing={3}>
                  <Heading size="sm" color="blue.300">
                    {articleSelected.Newsoutlet}
                  </Heading>
                  <Heading size="sm"> {articleSelected.ArticleTitle}</Heading>

                  <Stack h="54vh" overflow="auto">
                    {listOfSentences.map((sentence) => (
                      <Box
                        borderRadius="sm"
                        border="1px"
                        borderColor="gray.300"
                        mr={1}
                        p={2}
                        key={`sentences-${sentence.Id}`}
                      >
                        <Stack isInline>
                          <Text fontWeight="bold">{sentence.Order}</Text>
                          <Text
                            bg={
                              sentence.Id === articleSelected.SentenceId
                                ? "teal.200"
                                : ""
                            }
                          >
                            {sentence.Content}
                          </Text>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSentences;
