import React from "react";
import { Box, Flex, HStack, Stack, Text, useColorMode } from "@chakra-ui/react";
import { textColor } from "../../../../../config/Theme";
import { getAnnotationIcon } from "../../../../../assets/annotationIcons";
import { getBackgroundColor } from "../../helper";

const ProposedSanitizedItem = ({
  name,
  type,
  modifiedBy,
  status,
  changedOn,
  parentPath,
  hierarchicalIndex,
}) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      borderRadius="sm"
      border="1px"
      borderColor={status === "Create" ? "green.300" : "red.400"}
      w={350}
      color={textColor[colorMode]}
      bg={getBackgroundColor(hierarchicalIndex)}
    >
      <Flex>
        <HStack>
          <Box height="70px"></Box>
          <img
            width="30px"
            src={getAnnotationIcon(type, 0)}
            title={`Modified by ${modifiedBy.split("@")[0]} on ${changedOn}`}
            alt="Images"
          />

          <Stack color={textColor[colorMode]} spacing={1}>
            <Text
              fontSize="sm"
              isTruncated
              title={parentPath.split(" | ").join("\n")}
              color="black"
            >
              {name}
            </Text>

            <Text fontSize="xs" isTruncated color="black">
              {type}
            </Text>
          </Stack>
        </HStack>
      </Flex>
    </Box>
  );
};

export default ProposedSanitizedItem;
