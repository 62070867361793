import CARDINAL from "./CARDINAL.png";
import DATE from "./DATE.png";
import EVENT from "./EVENT.png";
import FAC from "./FAC.png";
import GPE from "./GPE.png";
import LANGUAGE from "./LANGUAGE.png";
import LAW from "./LAW.png";
import LOC from "./LOC.png";
import MONEY from "./MONEY.png";
import NORP from "./NORP.png";
import ORDINAL from "./ORDINAL.png";
import ORG from "./ORG.png";
import PERCENT from "./PERCENT.png";
import PERSON from "./PERSON.png";
import PRODUCT from "./PRODUCT.png";
import QUANTITY from "./QUANTITY.png";
import TIME from "./TIME.png";
import WORK_OF_ART from "./WORK_OF_ART.png";

import CARDINAL_P from "./CARDINAL_P.png";
import DATE_P from "./DATE_P.png";
import EVENT_P from "./EVENT_P.png";
import FAC_P from "./FAC_P.png";
import GPE_P from "./GPE_P.png";
import LANGUAGE_P from "./LANGUAGE_P.png";
import LAW_P from "./LAW_P.png";
import LOC_P from "./LOC_P.png";
import MONEY_P from "./MONEY_P.png";
import NORP_P from "./NORP_P.png";
import ORDINAL_P from "./ORDINAL_P.png";
import ORG_P from "./ORG_P.png";
import PERCENT_P from "./PERCENT_P.png";
import PERSON_P from "./PERSON_P.png";
import PRODUCT_P from "./PRODUCT_P.png";
import QUANTITY_P from "./QUANTITY_P.png";
import TIME_P from "./TIME_P.png";
import WORK_OF_ART_P from "./WORK_OF_ART_P.png";

export const getAnnotationIcon = (annotationType, status) => {
  let annotation = [];
  switch (annotationType) {
    case "CARDINAL":
      annotation = [CARDINAL_P, CARDINAL];
      break;

    case "DATE":
      annotation = [DATE_P, DATE];
      break;

    case "EVENT":
      annotation = [EVENT_P, EVENT];
      break;
    case "FAC":
      annotation = [FAC_P, FAC];
      break;

    case "GPE":
      annotation = [GPE_P, GPE];
      break;

    case "LANGUAGE":
      annotation = [LANGUAGE_P, LANGUAGE];
      break;

    case "LAW":
      annotation = [LAW_P, LAW];
      break;

    case "LOC":
      annotation = [LOC_P, LOC];
      break;
    case "MONEY":
      annotation = [MONEY_P, MONEY];
      break;
    case "NORP":
      annotation = [NORP_P, NORP];
      break;
    case "ORDINAL":
      annotation = [ORDINAL_P, ORDINAL];
      break;
    case "ORG":
      annotation = [ORG_P, ORG];
      break;
    case "PERCENT":
      annotation = [PERCENT_P, PERCENT];
      break;
    case "PERSON":
      annotation = [PERSON_P, PERSON];
      break;
    case "PRODUCT":
      annotation = [PRODUCT_P, PRODUCT];
      break;
    case "QUANTITY":
      annotation = [QUANTITY_P, QUANTITY];
      break;
    case "TIME":
      annotation = [TIME_P, TIME];
      break;
    case "WORK_OF_ART":
      annotation = [WORK_OF_ART_P, WORK_OF_ART];
      break;

    case "PRODUCT-CONTAINER":
      annotation = [PRODUCT_P, PRODUCT];
      break;
    case "ORG-CONTAINER":
      annotation = [ORG_P, ORG];
      break;
    default:
      annotation = [WORK_OF_ART_P, WORK_OF_ART];
      break;
  }

  return annotation[status];
};
