import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { createBrowserHistory } from "history";
import Auth from "./components/Auth/Auth";
import Dqe from "./components/Dqe/Dqe";
import Manager from "./components/Manager/Manager";
import Admin from "./components/Admin/Admin";
// import Error from "./components/Shared/Error";

class AppRouter extends Component {
  render() {
    // const history = createBrowserHistory();
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Auth} />
          <Route path="/dqe" component={Dqe} />
          <Route exact path="/manager" component={Manager} />
          <Route path="/admin" component={Admin} />
          {/* <Route path="*" component={Error} /> */}
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
