import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    authenticated: false,
    isBlocked: true,
    userName: "",
    fullName: "",
    roleMembership: [
      {
        RoleID: "",
        RoleName: "",
        IsBlockedForThatRole: true,
      },
    ],
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload.authenticated;
    },
    setUser: (state, action) => {
      state.isBlocked = action.payload.isBlocked;
      state.userName = action.payload.userName;
      state.fullName = action.payload.fullName;
    },
    setRoleMembership: (state, action) => {
      state.roleMembership = action.payload.roles;
    },
  },
});

export const {
  setAuthenticated,
  setUser,
  setRoleMembership,
} = AuthSlice.actions;
export default AuthSlice.reducer;
