import axios from "axios";
import { ToastService } from "../services/ToastService";

export const GeneralApi = {
  ListSentencesForAnnotation: async (searchParameters) => {
    const response = await axios.post(
      "ListSentencesForAnnotation?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data.ListSentencesForAnnotationCollection;
      } else {
        ToastService.errorToast(
          "List Sentences",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("ListSentencesForAnnotation", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  ListAnnotationType: async (searchParameters) => {
    const response = await axios.post("ListAnnotationType?", searchParameters);

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data.ListAnnotationTypeCollection;
      } else {
        ToastService.errorToast(
          "List Annotation Type",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("ListAnnotationType", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  ListSentencesForArticle: async (searchParameters) => {
    const response = await axios.post(
      "ListSentencesForArticle?",
      searchParameters
    );

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data.ListSentencesForArticleCollection;
      } else {
        ToastService.errorToast(
          "List Sentences For Article Collection",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("List Sentences For Article Collection", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
  ListUsers: async () => {
    const response = await axios.post("ListUsers?");

    if (response.status === 200) {
      if (response.data.ApiExecutionInfo.Status === "Success") {
        return response.data.ListUsersCollection;
      } else {
        ToastService.errorToast(
          "List Users Collection",
          response.data.ApiExecutionInfo.Exception
        );
        console.log("List Users Collection", response.data.ApiExecutionInfo);
        return null;
      }
    }
  },
};
