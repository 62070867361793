export const roleRoutes = {
  "151FAEE1-EBA3-4807-899D-EE021F7F01E2": "admin",
  "C369ADCD-7B30-4C31-B619-712A8B09FA68": "dqe",
};

export const roleID = [
  "151FAEE1-EBA3-4807-899D-EE021F7F01E2",
  "C369ADCD-7B30-4C31-B619-712A8B09FA68",
];

export const treeDropHereItem = [{ Id: "0", Name: "Drop Here" }];
export const appVersion = "v1.98";
