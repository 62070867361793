import { createStandaloneToast } from "@chakra-ui/react";
const toast = createStandaloneToast();

export const ToastService = {
  successToast: (title, description) => {
    toast({
      title: title,
      description: description,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  },
  errorToast: (title, description) => {
    toast({
      title: title,
      description: description,
      status: "error",
      duration: 100000,
      isClosable: true,
    });
  },
  warningToast: (title, description) => {
    toast({
      title: title,
      description: description,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  },
  infoToast: (title, description) => {
    toast({
      title: title,
      description: description,
      status: "info",
      duration: 5000,
      isClosable: true,
    });
  },
};
