import axios from "axios";

export const getRoleMembership = async () => {
  const response = await axios.post("GetRoleMembership?");

  if (response.status === 200) {
    if (response.data.ApiExecutionInfo.Status === "Success") {
      return response.data.GetRoleMembershipCollection;
    } else {
      console.log("Auth error", response.data.ApiExecutionInfo);
      return null;
    }
  }
};

export const getUserProfile = async () => {
  const response = await axios.post("GetUserInfo?");

  if (response.status === 200) {
    if (response.data.ApiExecutionInfo.Status === "Success") {
      return {
        fullName: response.data.FullName,
        userName: response.data.UserName,
        isBlocked: response.data.IsBlocked,
      };
    } else {
      console.log("User profile error", response.data.ApiExecutionInfo);
      return null;
    }
  }
};
