import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Checkbox,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Spinner,
} from "@chakra-ui/react";

import { shallowEqual, useSelector } from "react-redux";
import { DqeEditorApi } from "../../../../../api/DqeEditorApi";
import { ToastService } from "../../../../../services/ToastService";
import { createTree } from "../../helper";
import { SearchAutoComplete } from "../../../../Shared/SearchAutoComplete";
import { RepeatIcon } from "@chakra-ui/icons";

const ListSanitizedModalAnnotation = (props) => {
  const { setTreeData, setTreeAllData } = props;
  const annotationTypes = useSelector(
    (state) => state.dqeEditor.annotationType,
    shallowEqual
  );
  const searchTerm = useRef(null);
  const [annotationType, setAnnotation] = useState([]);
  const [strict, setStrict] = useState(false);
  const [search, setSearch] = useState(false);

  const searchApi = () => {
    setSearch(true);

    if (searchTerm.current.value.length === 0) {
      DqeEditorApi.ListSanitizedAnnotationByLevel({
        Id: null,
      })
        .then((data) => {
          if (data.ListSanitizedAnnotationByLevelCollection != null) {
            const tempData = data.ListSanitizedAnnotationByLevelCollection.map(
              (item) => {
                item["hasChildren"] = true;
                return item;
              }
            );
            setTreeData(createTree(tempData));
            setTreeAllData(tempData);
          } else {
            ToastService.errorToast(
              "List Sanitized Annotation By Level Collection Failed",
              data.ApiExecutionInfo.Exception
            );
          }
        })
        .finally(() => {
          setSearch(false);
        });
    } else {
      DqeEditorApi.ListSanitizedAnnotation({
        Name: searchTerm.current.value,
        Type: annotationType.length === 0 ? null : annotationType.join(";"),
        StrictSearch: strict,
      })
        .then((data) => {
          if (data.ListSanitizedAnnotationCollection != null) {
            const tempData = data.ListSanitizedAnnotationCollection.map(
              (item) => {
                item["hasChildren"] = true;
                return item;
              }
            );
            setTreeData(createTree(tempData));
            setTreeAllData(tempData);
          } else {
            ToastService.errorToast(
              "List Sanitized Annotation Search Failed",
              data.ApiExecutionInfo.Exception
            );
          }
        })
        .finally(() => setSearch(false));
    }
  };

  useEffect(() => {
    props.listSanitizedAnnotationSearchModal(searchApi);
  }, []);

  return (
    <Stack spacing={3}>
      <FormControl>
        <InputGroup size="sm">
          <Input
            size="sm"
            type="text"
            placeholder="Eg: Mr robot"
            ref={searchTerm}
            onKeyDown={(event) => (event.key === "Enter" ? searchApi() : "")}
          />
          <InputRightElement>
            {search ? (
              <Spinner color="blue.400" thickness="3px" />
            ) : (
              <IconButton
                variant="ghost"
                colorScheme="blue"
                onClick={() => {
                  searchApi();
                  setSearch(true);
                }}
                icon={<RepeatIcon />}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <SearchAutoComplete
        allItems={annotationTypes}
        onItemSelect={(selectedAnnotations) => {
          setAnnotation(selectedAnnotations);
        }}
      />
      <Stack spacing={3} direction="row">
        <Checkbox
          label="strict"
          onChange={(event) => {
            event.preventDefault();
            setStrict(event.target.checked);
          }}
        >
          Strict Search
        </Checkbox>
      </Stack>
    </Stack>
  );
};

export default ListSanitizedModalAnnotation;
