import { CloseButton, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Card } from "../../../Shared/Common";

import { getBackgroundColor } from "../helper";
import { getAnnotationIcon } from "../../../../assets/annotationIcons";

const TreeItem = ({
  id,
  title,
  type,
  finalVersion,
  Count,
  userName,
  modifiedBy,
  hierarchicalIndex,
  listAction,
  itemIndex,
  parentPath,
  statusChangedOn,
}) => {
  const [closeVisibility, setCloseVisibility] = useState(false);

  const handleRemoveClick = () => {
    let resp = window.confirm(
      "Are you sure? You can't undo this action afterwards."
    );
    if (resp) {
      listAction(
        0,
        `${id}|${title}`,
        finalVersion === 1 ? "deleteSan" : "cancelPSan"
      );
    }
  };

  return (
    <Card
      p={0}
      key={id}
      onMouseEnter={() => setCloseVisibility(true)}
      onMouseLeave={() => setCloseVisibility(false)}
      onContextMenu={(event) => {
        event.preventDefault();
        listAction(0, 0, "ModalTree");
      }}
    >
      <HStack
        py={0.5}
        w={200}
        border="2px"
        bg={getBackgroundColor(hierarchicalIndex)}
        borderColor="gray.300"
      >
        {finalVersion === 1 ||
        userName.toLowerCase() === modifiedBy.toLowerCase() ? (
          closeVisibility ? (
            <CloseButton
              size="sm"
              boxSize="30px"
              onClick={handleRemoveClick}
              title={
                finalVersion === 0
                  ? `Modified by ${
                      modifiedBy.split("@")[0]
                    } on ${statusChangedOn}`
                  : ""
              }
            />
          ) : (
            <img
              width="30px"
              src={getAnnotationIcon(type, finalVersion)}
              alt="tree-Images"
            />
          )
        ) : (
          <img
            width="30px"
            src={getAnnotationIcon(type, finalVersion)}
            alt="tree-Images"
            title={
              finalVersion === 0
                ? `Modified by ${
                    modifiedBy.split("@")[0]
                  } on ${statusChangedOn}`
                : ""
            }
          />
        )}

        <Stack direction="column" spacing={0.5}>
          <Text isTruncated w={36} title={parentPath.split(" | ").join("\n")}>
            {title}
          </Text>

          <Text fontSize="xs" isTruncated>
            {type}
          </Text>
          <Text fontSize="xs" isTruncated>
            {`${Count} annotations`}
          </Text>
        </Stack>
      </HStack>
    </Card>
  );
};

export default TreeItem;
