import React from "react";
import { useSelector } from "react-redux";
import { logout } from "../../services/AuthService.js";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Box,
  Text,
} from "@chakra-ui/react";
import { ArrowForwardIcon, AtSignIcon } from "@chakra-ui/icons";

const Profile = () => {
  const profileState = useSelector((state) => state.auth);
  return (
    <Box zIndex="3">
      <Menu size="xs">
        {({ isOpen }) => (
          <>
            <MenuButton isActive={isOpen}>
              <Text>{profileState.fullName}</Text>
            </MenuButton>
            <MenuList>
              <MenuGroup title="Profile">
                <MenuItem>{profileState.userName}</MenuItem>
                <MenuItem icon={<AtSignIcon />}>
                  {profileState.fullName}
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuItem icon={<ArrowForwardIcon />} onClick={() => logout()}>
                Log Out
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default Profile;
