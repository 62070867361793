import { Box } from "@chakra-ui/react";

export const Card = (props) => (
  <Box
    px="4"
    py="5"
    rounded="sm"
    shadow="lg"
    borderRadius="lg"
    bg="white"
    {...props}
  />
);
