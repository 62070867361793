// theme.js
import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const purpleHeart = "#583FCF";
export const dodgerBlue = "#008CFF";
export const grey = "#828282";
export const veryLightGrey = "#C9C9C9";

export const white = "#FFFFFF";
export const black = "#000000";

export const lighterblack = "#322f3d";

export const bgColor = { light: "white.300", dark: "black.600" };
export const bgColorItem = { light: "white", dark: "gray.700" };
// export const bgColorBox = { light: "white", dark: "gray.700" };
export const bgColorBox = { light: "white", dark: "gray.900" };
export const textColor = { light: "black", dark: "white" };
export const borderColor = { light: "black", dark: "white" };

// Version 1: Using objects
export const theme = extendTheme({
  styles: {
    global: (props) => ({
      // styles for the `body`
      body: {
        // bg: mode("white", "gray.900")(props),
        bg: mode("white", "gray.800")(props),
      },
    }),
  },
  components: {
    // Popover: {
    //   // 2. We can add a new button size or extend existing
    //   sizes: {
    //     xl: {
    //       h: "100px",
    //       fontSize: "lg",
    //       px: "32px",
    //     },
    //   },
    // },
    // Popover: {
    //   baseStyle: {
    //     popper: {
    //       height: "30rem",
    //       width: "20rem",
    //       maxWidth: "40rem",
    //     },
    //   },
    // },
    // Popover: {
    //   variants: {
    //     responsive: {
    //       popper: {
    //         maxWidth: "100%",
    //         width: "100%",
    //       },
    //     },
    //   },
    // },
  },
});
